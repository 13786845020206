/* eslint-disable react-hooks/exhaustive-deps */
import CustomPagination from "App/Components/CustomPagination";
import CustomTable from "App/Components/CustomTable";
import PageDescription from "App/Components/PageDescription";
import useCollectionsHistoryQuery from "App/Pages/Collections/ColletionsHistory/query";
import { useCollectionsHistoryState } from "App/Pages/Collections/ColletionsHistory/store";
import URLS from "Routes/constants";
import { useExtendedApiRequest } from "Hooks/ExtendedAPI";
import { useNavigate } from "react-router-dom";
import { FORMATS } from "Constants/Formats";
import { FLAGS } from "Constants/Images";
import { accountingFormat } from "Utils";
import { Button, Col, DatePicker, Image, Input, Row, Space, notification } from "antd";
import { debounce } from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { FaFileDownload, FaRegCalendarAlt } from "react-icons/fa";

export default function CollectionsHistory() {
  const currentPage = useCollectionsHistoryState(s => s.currentPage);
  const limit = useCollectionsHistoryState(s => s.limit);
  const setState = useCollectionsHistoryState(s => s.setState);
  const dateFilter = useCollectionsHistoryState(state => state.dateFilter);
  const search = useCollectionsHistoryState(state => state.search);
  const sort = useCollectionsHistoryState(state => state.sort);
  const { data, isLoading } = useCollectionsHistoryQuery({ currentPage, limit, dateFilter, search, sort });
  const resetState = useCollectionsHistoryState(s => s.resetState);
  const extendedApiRequest = useExtendedApiRequest();
  const navigate = useNavigate();
  const COLUMNS = [
    {
      title: <DatePicker bordered={false} className="pointer" suffixIcon={<FaRegCalendarAlt className="dark-green" size={18} />} style={{ width: '20px', padding: '0px' }} inputReadOnly onChange={(_, dateFilter) => setState({ dateFilter, currentPage: 1 })} />,
      width: 20
    },
    {
      title: 'Date & Time',
      dataIndex: 'create_time',
      key: 'create_time',
      align: 'left',
      sorter: true,
      render: val => {
        const date = moment(val);
        return (
          date?.isValid() ?
            <Space direction="vertical">
              {date?.format(FORMATS.date)}
              {date?.format(FORMATS.time)}
            </Space> :
            "-"
        )
      }
    },
    {
      title: 'Sender',
      dataIndex: 'sender_name',
      key: 'sender_name',
      align: 'left'
    },
    {
      title: 'Currency',
      dataIndex: ["currency", "currency"],
      key: 'currency_id',
      align: 'left',
      sorter: true,
      render: text => <Space>{FLAGS?.[text] && <Image src={FLAGS?.[text]} preview={false} alt={text} width={36} />}{text}</Space>
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      sorter: true,
      render: text => accountingFormat(text)
    },
    {
      title: 'Reference',
      dataIndex: 'additional_info',
      key: 'additional_info',
    },
    {
      dataIndex: 'reference_uuid',
      key: 'action',
      align: 'right',
      render: (_, record) => <Button type="primary" onClick={() => downLoadPDF(record?.id)}>
        <Space>
          <FaFileDownload size={18} style={{ marginBottom: "-2px" }} /> Download PDF Statement
        </Space>
      </Button>
    }
  ]

  useEffect(() => resetState(), [])

  const handleTableChange = (pagination, filters, sorter) => {
    const column = sorter?.order && sorter?.columnKey ? sorter.columnKey : '';
    const order = sorter?.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '';
    const sort = column && order && `${column},${order}`;
    setState({ sort });
  }

  //
  const downLoadPDF = async (id) => {
    const resp = await extendedApiRequest({
      baseUrl: process.env.REACT_APP_API_BASE_DOWNLOAD_URL,
      path: 'collectionReport?id=' + id,
      method: 'GET',
      config: {
        responseType: "blob",
      },

    });
    const acceptedFileTypes = {
      'application/pdf': '.pdf',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
      'text/csv': '.csv',
    };

    if (!resp.size || !resp.type || !Object.keys(acceptedFileTypes).includes(resp.type)) {
      notification.error({ message: 'Something went wrong!', placement: 'top' });
      return;
    }
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(resp);
    link.download = `${id}.pdf`;
    link.click();
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <PageDescription title='Collections History' text='- Overview of previous Collections' />
        </Col>
      </Row>
      <Row className="m-t-25" justify="space-between">
        <Col xxl={4}>
          <Input.Search placeholder="Search Sender Name" onChange={debounce((e) => setState({ search: e?.target?.value }), 1000)} />
        </Col>
        <Col>
          <Row gutter={12}>
            <Col>
              <Button type="primary" onClick={() => navigate(`${URLS.CollectionReports}`)}>Collection Reports</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="m-t-10">
        <Col span={24}>
          <CustomTable
            columns={COLUMNS}
            dataSource={data?.data}
            loading={isLoading}
            rowClassName='medium fs-18px'
            rowKey="reference_uuid"
            styleAllRows
            className="spaced-rows"
            onChange={handleTableChange}
          />
          <CustomPagination
            loading={isLoading}
            onPageChange={(page) => setState({ currentPage: page })}
            total={data?.pager?.count ?? 0}
            pageSize={limit}
            current={currentPage}
            onPageSizeChange={(_, size) => setState({ limit: size, currentPage: 1 })}
          />
        </Col>
      </Row>
    </>
  )
}