import { useQuery } from "@tanstack/react-query";
import { APIROUTES } from "Constants/ApiRoutes";
import { useApiRequest } from "Hooks/API";

export function usePilotFeaturesQuery() {
  const apiRequest = useApiRequest();

  // eslint-disable-next-line no-unused-vars
  const fetchQuery = async () => {
    const { data } = await apiRequest(`${APIROUTES.get.features}?pilot=1`);
    return data || [];
  }

  const query = useQuery({
    queryKey: ['_features'],
    queryFn: fetchQuery,
  });

  return query;
}