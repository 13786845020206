import create from "zustand";

export const useCollectionsHistoryState = create(set => ({
  currentPage: 1,
  limit: 10,
  dateFilter: undefined,
  search: undefined,
  sort: undefined,
  setState: obj => set(obj),
  resetState: () => set({
    currentPage: 1,
    limit: 10,
    dateFilter: undefined,
    search: undefined,
    sort: undefined
  })
}))