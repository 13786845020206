/* eslint-disable react-hooks/exhaustive-deps */
import AlertsCarousel from "App/Components/AlertsCarousel";
import DashboardCarousel from "App/Components/DashboardCarousel";
import { Button, Col, Divider, Image, Row, Space, Typography } from "antd";
import history from 'Assets/Images/carousel5.png';
import accounts from 'Assets/Images/carousel3.png';
import WalletBreakdown from "App/Components/WalletBreakdown";
import CustomTable from "App/Components/CustomTable";
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";
import useCollectionsHistoryQuery from "App/Pages/Collections/ColletionsHistory/query";
import { accountingFormat } from "Utils";
import { FLAGS } from "Constants/Images";
import { useCollectionsBalancesQuery } from "App/Pages/Collections/Accounts/query";
import { useCollectionsDashboardState } from "App/Pages/Collections/CollectionsDashboard/store";
import { useEffect } from "react";
import { useLoginStore } from "App/Pages/Login/store";

const columns = [
  {
    title: 'Sender',
    dataIndex: 'sender_name',
    key: 'sender_name',
  },
  {
    title: 'Received',
    dataIndex: 'amount',
    key: 'amount',
    render: (val, obj) => <Space>
      {FLAGS?.[obj?.currency?.currency] &&
        <Image src={FLAGS?.[obj?.currency?.currency]} preview={false} alt={obj?.currency?.currency} width={36} />}
      {accountingFormat(val)}
    </Space>
  },
  {
    title: 'Notes',
    key: 'additional_info',
    dataIndex: 'additional_info',
  },
];

export default function CollectionsDashboard() {
  const { data: collections, isLoading: collectionsLoading } = useCollectionsHistoryQuery({ currentPage: 1, limit: 5 });
  const { data: collectionsBalances, isLoading: balancesLoading } = useCollectionsBalancesQuery();
  const config = useLoginStore(s => s.config);
  const currencies = useCollectionsDashboardState(s => s.currencies);
  const carouselBalances = useCollectionsDashboardState(s => s.carouselBalances);
  const setState = useCollectionsDashboardState(s => s.setState);
  const navigate = useNavigate();
  const dashboardCarouselCards = [
    {
      image: <Image src={history} preview={false} height={170} />,
      text: 'Collections History',
      cardProps: {
        onClick: () => navigate(URLS.CollectionsHistory)
      }
    },
    {
      image: <Image src={accounts} preview={false} height={170} />,
      text: 'Accounts',
      cardProps: {
        onClick: () => navigate(URLS.CollectionsAccounts)
      }
    },
  ];

  useEffect(() => {
    if (collectionsBalances) {
      const currencies = Object.keys(collectionsBalances).sort(
        (a, b) => a?.localeCompare(b)).sort(
          (a, b) => config?.currencies_order?.find(item => item?.currency === a)?.sort_order - config?.currencies_order?.find(item => item?.currency === b)?.sort_order).map(
            key => ({
              currency: key,
              cleared: collectionsBalances?.[key]?.balance,
              available_amount: collectionsBalances?.[key]?.balance
            })).sort(
              (a, b) => +a?.available_amount && +b?.available_amount ? 0 :
                +a?.available_amount && !+b?.available_amount ? -1 : 1
            )
      const carouselBalances = [
        {
          currency: "GBP",
          amount: collectionsBalances?.GBP?.balance,
        },
        {
          currency: "EUR",
          amount: collectionsBalances?.EUR?.balance,
        },
        {
          currency: "USD",
          amount: collectionsBalances?.USD?.balance,
        },
      ]
      setState({ currencies, carouselBalances })
    }
  }, [collectionsBalances])


  return (
    <Row justify="space-between">
      <Col xxl={16} xl={24} lg={24} md={24} sm={24} xs={24}>
        <DashboardCarousel cards={dashboardCarouselCards} balances={carouselBalances} balancesLoading={balancesLoading} />
      </Col>
      <Col xxl={1} xl={0} lg={0} ms={0} sm={0} xs={0}>
        <Divider type='vertical' style={{ height: '100%', margin: '0 50%' }} />
      </Col>
      <Col xxl={7} xl={9} lg={11} md={14} sm={18} xs={24}>
        <AlertsCarousel />
      </Col>
      <Col className='m-t-20' xxl={9} xl={12} lg={16} md={20} sm={24} xs={24} >
        <WalletBreakdown loading={balancesLoading} balances={currencies} title="Account Breakdown" />
      </Col>
      <Col xxl={8} xl={8} lg={12} md={16} sm={18} xs={24} />
      <Col className="m-t-20" xxl={7} xl={12} lg={16} md={18} sm={20} xs={24} >
        <Row justify="space-between" align="bottom">
          <Col>
            <Typography.Text className='medium fs-25px dark-green'>Recent Collections</Typography.Text>
          </Col>
          <Col>
            <Button type="link" className='medium fs-18px pointer' onClick={() => navigate(URLS.CollectionsHistory)}>View All</Button>
          </Col>
        </Row>
        <Row className="m-t-5">
          <Col span={24}>
            <CustomTable
              columns={columns}
              dataSource={collections?.data}
              showHeader={true}
              loading={collectionsLoading}
              scroll={{ x: 390 }}
              rowKey="id"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}