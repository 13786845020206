/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { AiOutlineSync } from 'react-icons/ai';
import { Row, Col, Input, DatePicker, Button, Typography, Space, Form, Popconfirm, Image, Modal, Card, notification, Spin, Pagination, Tooltip } from 'antd';
import PageDescription from "App/Components/PageDescription";
import CustomTable from "App/Components/CustomTable";
import moment from 'moment';
import { useApiRequest } from "Hooks/API";
import { useFundingHistoryState } from "App/Pages/Wallet/FundingHistory/store";
import { FLAGS } from "Constants/Images";
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";
import debounce from 'lodash/debounce'
import getSymbolFromCurrency from "currency-symbol-map";
import { accountingFormat } from "Utils";
import { FORMATS } from "Constants/Formats";
import { APIROUTES } from "Constants/ApiRoutes";
import { FaRegCalendarAlt } from "react-icons/fa";
import { OPT_FUNDING_METHOD } from "Constants/Opts";
import { useCheckAccountSuspensionQuery } from "Hooks/Queries";
import { useLoginStore } from "App/Pages/Login/store";
// import Search from "antd/lib/transfer/search";

function FundingHistory() {
    const apiRequest = useApiRequest();
    const setState = useFundingHistoryState(state => state.setState);
    const fundingIndex = useFundingHistoryState(state => state.fundingIndex);
    const showModal = useFundingHistoryState(state => state.showModal);
    const fundingHistory = useFundingHistoryState(state => state.fundingHistory);
    const currency = useFundingHistoryState(state => state.currency);
    const amount = useFundingHistoryState(state => state.amount);
    const description = useFundingHistoryState(state => state.description);
    const fundingMethod = useFundingHistoryState(state => state.fundingMethod);
    const loadingFund = useFundingHistoryState((state) => state.loadingFund);
    const contentIndex = useFundingHistoryState(state => state.contentIndex);
    const message = useFundingHistoryState(state => state.message);
    const fundingHistoryLoading = useFundingHistoryState(state => state.fundingHistoryLoading);
    const volopaAccountHolderName = useFundingHistoryState(state => state.volopaAccountHolderName);
    const volopaAccountSortCode = useFundingHistoryState(state => state.volopaAccountSortCode);
    const volopaAccountNumber = useFundingHistoryState(state => state.volopaAccountNumber);
    const volopaAccountCountry = useFundingHistoryState(state => state.volopaAccountCountry);
    const volopaAccountAddress = useFundingHistoryState(state => state.volopaAccountAddress);
    const volopaAccountName = useFundingHistoryState(state => state.volopaAccountName);
    const volopaAccountPaymentRef = useFundingHistoryState(state => state.volopaAccountPaymentRef);
    const volopaAccountIban = useFundingHistoryState(state => state.volopaAccountIban);
    const volopaAccountSwift = useFundingHistoryState(state => state.volopaAccountSwift);
    const volopaAccountNumberType = useFundingHistoryState(state => state.volopaAccountNumberType);
    const volopaAccountSortCodeLabel = useFundingHistoryState(state => state.volopaAccountSortCodeLabel);
    const volopaAccountDetails = useFundingHistoryState(state => state.volopaAccountDetails);
    const fundingLimitReached = useFundingHistoryState(state => state.fundingLimitReached);
    const loadingFundingData = useFundingHistoryState(state => state.loadingFundingData);
    const totalPages = useFundingHistoryState(state => state.totalPages);
    const navigate = useNavigate();
    const { data: accountStatus, isFetching: accountStatusFetching } = useCheckAccountSuspensionQuery();
    const config = useLoginStore(s => s.config);

    const toggleModal = () => setState({ showModal: !showModal });
    const [modalForm] = Form.useForm();
    const currentPage = useRef(1);
    const limit = useRef(20);
    const dateFilter = useRef();
    const search = useRef();

    const paginationStyles = {
        marginTop: '30px',
        marginBottom: '20px',
        padding: '8px 18px',
        background: '#eee',
        borderRadius: '8px'
    };

    const pageDescriptionProps = {
        title: 'Your Transaction History',
        text: [
            '- Overview of payments to your Company Wallet',
            '- Search History by Admin Name and Description'
        ]
    }
    const datepickerChange = async (date, dateString) => {
        dateFilter.current = dateString;
        getFundingHistory();
    }
    const columns = [
        {
            title: <DatePicker bordered={false} className="pointer" suffixIcon={<FaRegCalendarAlt className="dark-green" size={18} />} style={{ width: '20px', padding: '0px' }} inputReadOnly onChange={datepickerChange} />,
            width: 20
        },
        {
            title: 'Submission Date',
            dataIndex: 'submissionDate',
            key: 'submissionDate',
            render: text => moment(text, 'DD-MM-YYYY').format(FORMATS.date),
            sorter: (a, b) => a.submissionDate && b.submissionDate && moment(a.submissionDate, 'DD-MM-YYYY').diff(moment(b.submissionDate, 'DD-MM-YYYY'))
        },
        {
            title: 'Receipt Date',
            dataIndex: 'receiptDate',
            key: 'receiptDate',
            render: text => text ? moment(text, 'DD-MM-YYYY').format(FORMATS.date) : '',
            sorter: (a, b) => a.receiptDate && b.receiptDate && moment(a.receiptDate, 'DD-MM-YYYY').diff(moment(b.receiptDate, 'DD-MM-YYYY'))
        },
        {
            title: 'Admin',
            dataIndex: 'admin',
            key: 'admin',
            sorter: (a, b) => a.admin && b.admin && a.admin.localeCompare(b.admin)
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => a.description && b.description && a.description.localeCompare(b.description)
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount && b.amount && a.amount - b.amount,
            render: (a, row) => {
                return (
                    <Typography.Text>{getSymbolFromCurrency(row?.currency)}{accountingFormat(a)}</Typography.Text>
                )
            }
        },
        {
            title: <Typography.Text className='dark-green'>Funding Method</Typography.Text>,
            dataIndex: 'fundingMethodId',
            key: 'fundingMethod',
            render: val => <Typography.Text className='dark-green'>{
                val === OPT_FUNDING_METHOD.EASY_TRANSFER ?
                    'Easy Transfer' :
                    val === OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS ?
                        'Manual Push Funds' :
                        "Volopa Collection Account"
            }</Typography.Text>,
            sorter: (a, b) => a.fundingMethodId && b.fundingMethodId && a.fundingMethodId - b.fundingMethodId
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: text => <Typography.Text type={text === 'Awaiting Funds' ? 'warning' : text === 'Cancelled' ? 'danger' : text === 'Complete' ? 'success' : ''}>
                {text}
            </Typography.Text>,
            sorter: (a, b) => a.status && b.status && a.status.localeCompare(b.status)
        },
        {
            dataIndex: 'key',
            key: 'action',
            render: (key) => (
                <Tooltip
                    title="This account is currently frozen for administrative reasons. Our team will get in touch with you soon, if needed."
                    {...(
                        (
                            !config.is_eea ? !accountStatus?.suspended_accounts?.payments :
                                !accountStatus?.suspended_accounts?.cards
                        ) &&
                        { open: false }
                    )}
                >
                    <Popconfirm
                        title="Are you sure you want to repeat this transaction?"
                        placement="topRight"
                        onConfirm={(e) => {
                            e.stopPropagation();
                            confirmSubmit(key);
                        }}
                        onCancel={(e) => {
                            e.stopPropagation();
                        }}
                        okText="Yes"
                        cancelText="No"
                        disabled={
                            !config.is_eea ? !!accountStatus?.suspended_accounts?.payments :
                                !!accountStatus?.suspended_accounts?.cards
                        }
                    >
                        <Button
                            type='primary'
                            onClick={(e) => e.stopPropagation()}
                            disabled={
                                !config.is_eea ? !!accountStatus?.suspended_accounts?.payments :
                                    !!accountStatus?.suspended_accounts?.cards
                            }
                        >
                            <Space align='start'><AiOutlineSync size={18} className='m-t-2' />Repeat</Space>
                        </Button>
                    </Popconfirm>
                </Tooltip>
            ),
            align: 'right'
        }
    ];

    useEffect(() => {
        if (
            !config.is_eea ? !!accountStatus?.suspended_accounts?.payments :
                !!accountStatus?.suspended_accounts?.cards
        ) {
            notification.destroy();
            notification.error({
                description: 'This account is currently frozen for administrative reasons. Our team will get in touch with you soon, if needed.',
                placement: 'top'
            })
        }
    }, [accountStatus]);

    const submitFundingRequest = async () => {
        setState({ loadingFund: true });
        const data = {
            currency,
            amount,
            description,
            fund_method_id: fundingIndex?.fundingMethodId
        };
        const fundResponse = await apiRequest("tcc/company/wallet/repeat", "POST", data);
        if (fundResponse.response && fundResponse.response.data.error) {
            if (fundResponse.status === 400) {
                setState({ message: fundResponse.response.data.error.message });
            } else {
                notification.error({ message: 'Error Code: ' + fundResponse.response.data.error.error_number, description: fundResponse.response.data.error.message, placement: 'top' });
            }
            toggleModal()
            setState({ loadingFund: false });
        }
        if (fundResponse.data && fundResponse.data.company_wallet_fund_status_id) {
            if (fundResponse.data.company_wallet_fund_status_id === 1) {
                setState({ message: 'Repeat Transaction Complete' });
            }
            if (fundResponse.data.company_wallet_fund_status_id === 2) {
                setState({ message: 'Funding Cancelled' });
            }
            if (fundResponse.data.company_wallet_fund_status_id === 3) {
                setState({ message: 'Funding Complete' });
            }
            setState({ loadingFund: false, contentIndex: 1 });
            getFundingHistory();
        }
    };
    const getPaymentDetails = async (currency = null) => {
        setState({
            loadingFundingData: true
        });
        let response = await apiRequest("tcc/fund/account?currency=" + currency);
        if (response && response.data && response.data.result.funding_accounts?.find(account => account?.payment_type === "regular")) {
            let sortCodeLabelTag = 'Sort Code';
            let routingCodeTypeTag = response.data.result.funding_accounts?.find(account => account?.payment_type === "regular").routing_code_type;
            if (routingCodeTypeTag === 'bic_swift') {
                sortCodeLabelTag = 'SWIFT/BIC'
            }
            if (routingCodeTypeTag === 'ach_routing_number') {
                sortCodeLabelTag = 'Ach Routing Number'
            }
            if (routingCodeTypeTag === 'wire_routing_number') {
                sortCodeLabelTag = 'Wire Routing Number'
            }
            setState({
                volopaAccountHolderName: response.data.result.funding_accounts?.find(account => account?.payment_type === "regular").account_holder_name,
                volopaAccountSortCode: response.data.result.funding_accounts?.find(account => account?.payment_type === "regular").routing_code,
                volopaAccountNumber: response.data.result.funding_accounts?.find(account => account?.payment_type === "regular").account_number,
                volopaAccountCountry: response.data.result.funding_accounts?.find(account => account?.payment_type === "regular").bank_country,
                volopaAccountAddress: response.data.result.funding_accounts?.find(account => account?.payment_type === "regular").bank_address,
                volopaAccountNumberType: response.data.result.funding_accounts?.find(account => account?.payment_type === "regular").account_number_type,
                volopaAccountName: response.data.result.funding_accounts?.find(account => account?.payment_type === "regular").bank_name,
                volopaAccountSortCodeLabel: sortCodeLabelTag,
                volopaAccountDetails: response.data.result
            });
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with loading the Volopa funding details for this currency', placement: 'top' });
        }
        setState({
            loadingFundingData: false
        });
    }
    const updateFundingDetails = (e, over) => {
        let sortCodeLabel = 'Sort Code';
        if (over === false) {
            let routingCodeType = volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").routing_code_type;
            if (routingCodeType === 'bic_swift') {
                sortCodeLabel = 'SWIFT/BIC'
            }
            if (routingCodeType === 'ach_routing_number') {
                sortCodeLabel = 'Ach Routing Number'
            }
            if (routingCodeType === 'wire_routing_number') {
                sortCodeLabel = 'Wire Routing Number'
            }
            setState({
                volopaAccountHolderName: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").account_holder_name,
                volopaAccountSortCode: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").routing_code,
                volopaAccountNumber: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").account_number,
                volopaAccountCountry: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").bank_country,
                volopaAccountAddress: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").bank_address,
                volopaAccountName: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").bank_name,
                volopaAccountPaymentRef: volopaAccountDetails?.short_reference,
                volopaAccountIban: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").iban,
                volopaAccountSwift: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").bic_swift,
                volopaAccountNumberType: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").account_number_type,
                volopaAccountSortCodeLabel: sortCodeLabel,
                fundingLimitReached: false
            });
        } else {
            setState({
                volopaAccountHolderName: volopaAccountDetails?.settlement_accounts[0].bank_account_holder_name,
                volopaAccountSortCode: volopaAccountDetails?.settlement_accounts[0].routing_code_value_1,
                volopaAccountNumber: volopaAccountDetails?.settlement_accounts[0].account_number,
                volopaAccountCountry: volopaAccountDetails?.settlement_accounts[0].bank_country,
                volopaAccountAddress: volopaAccountDetails?.settlement_accounts[0].bank_address,
                volopaAccountName: volopaAccountDetails?.settlement_accounts[0].bank_name,
                volopaAccountPaymentRef: volopaAccountDetails?.short_reference,
                volopaAccountIban: volopaAccountDetails?.settlement_accounts[0].iban,
                volopaAccountSwift: volopaAccountDetails?.settlement_accounts[0].bic_swift,
                volopaAccountSortCodeLabel: sortCodeLabel,
                fundingLimitReached: true
            });
        }
    }
    const handlePageChange = (page, pageSize) => {
        currentPage.current = page;
        getFundingHistory();
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }

    const handlePageSizeChange = (current, size) => {
        limit.current = size;
        currentPage.current = current;
        getFundingHistory();
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }

    const handleEasyTransfer = async () => {
        try {
            setState({ loadingPayment: true, showModal: false });
            const body = {
                type: "FUND_WALLET",
                amount: amount,
                currency: currency,
                description: description,
                fundMethodId: 2,
            }
            const response = await apiRequest(`${APIROUTES.post.accounting_create_payment}`, 'POST', body);
            if (response.success) {
                setState({ contentIndex: 0, amount: undefined, currency: undefined, description: undefined, loadingFund: false });
                window.location.href = response?.response?.data?.hostedUrl;
            } else {
                notification.error({ message: 'Something went wrong!', placement: 'top' })
            }
        } catch {
            notification.error({ message: 'Something went wrong!', placement: 'top' })
        }
        finally {
            setState({ loadingPayment: false });
        }

    }

    const modalContent = [
        (
            <>
                <Spin spinning={loadingFundingData}>
                    <Row justify="center">
                        <Col>
                            <Typography.Text className="medium fs-18px dark-green">Funding Confirmation</Typography.Text>
                        </Col>
                    </Row>
                    <Row className='m-t-10' gutter={[24, 12]} justify='space-between'>
                        <Col>
                            <Button type='primary' {...(fundingLimitReached === true && { ghost: true })} onClick={(e) => updateFundingDetails(e, false)}>
                                Regular/Faster Payment Up To A Value Of £250,000.00
                            </Button>
                        </Col>
                        <Col>
                            <Button type='primary' {...(fundingLimitReached === false && { ghost: true })} onClick={(e) => updateFundingDetails(e, true)}>
                                Payments Over £250,000.00 Or BACS/CHAPS/SWIFT Payments
                            </Button>
                        </Col>
                    </Row>
                    <Row className="m-t-10">
                        <Col span={24} className='bg-light-grey rounded p-10'>
                            <Row gutter={16}>
                                <Col span={16}>
                                    <Space direction="vertical" size={0}>
                                        <Typography.Text className="muli semi-bold fs-18px dark-green">Account Holder Name</Typography.Text>
                                        <Typography.Text className="muli light fs-18px">{volopaAccountHolderName}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={8}>
                                    <Space direction="vertical" size={0}>
                                        <Typography.Text className="muli semi-bold fs-18px dark-green">{volopaAccountSortCodeLabel}</Typography.Text>
                                        <Typography.Text className="muli light fs-18px">{volopaAccountSortCode}</Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                            {fundingLimitReached &&
                                <Row className='m-t-10'>
                                    <Col span={16}>
                                        <Space direction='vertical' size={0}>
                                            <Typography.Text className='muli semi-bold dark-green fs-18px'>IBAN</Typography.Text>
                                            <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountIban}</Typography.Text>
                                        </Space>
                                    </Col>
                                    <Col span={8}>
                                        <Space direction='vertical' size={0}>
                                            <Typography.Text className='muli semi-bold dark-green fs-18px'>SWIFT/BIC</Typography.Text>
                                            <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountSwift}</Typography.Text>
                                        </Space>
                                    </Col>
                                </Row>
                            }
                            <Row className="m-t-10" gutter={16}>
                                <Col span={16}>
                                    {fundingLimitReached ?
                                        <Space direction="vertical" size={0}>
                                            <Typography.Text className="muli semi-bold fs-18px dark-green">Account Number</Typography.Text>
                                            <Typography.Text className="muli light fs-18px">{volopaAccountNumber}</Typography.Text>
                                        </Space> :
                                        <Space direction="vertical" size={0}>
                                            <Typography.Text className="muli semi-bold fs-18px dark-green">{volopaAccountNumberType === "iban" ? "IBAN" : "Account Number"}</Typography.Text>
                                            <Typography.Text className="muli light fs-18px">{volopaAccountNumber}</Typography.Text>
                                        </Space>
                                    }
                                </Col>
                                <Col span={8}>
                                    {!fundingLimitReached ?
                                        <Space direction='vertical' size={0}>
                                            <Typography.Text className='muli semi-bold dark-green fs-18px'>Bank Country</Typography.Text>
                                            <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountCountry}</Typography.Text>
                                        </Space> :
                                        <Space direction='vertical' size={0}>
                                            <Typography.Text className='muli semi-bold dark-green fs-18px'>Payment Reference</Typography.Text>
                                            <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountPaymentRef}</Typography.Text>
                                        </Space>
                                    }
                                </Col>
                            </Row>
                            <Row className="m-t-10" gutter={16}>
                                <Col span={24}>
                                    <Space direction="vertical" size={0}>
                                        <Typography.Text className="muli semi-bold fs-18px dark-green">Bank Name</Typography.Text>
                                        <Typography.Text className="muli light fs-18px">{volopaAccountName}</Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                            <Row className="m-t-10" gutter={16}>
                                <Col span={24}>
                                    <Space direction="vertical" size={0}>
                                        <Typography.Text className="muli semi-bold fs-18px dark-green">Bank Address</Typography.Text>
                                        <Typography.Text className="muli light fs-18px">{volopaAccountAddress}</Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="m-t-10" gutter={16}>
                        <Col span={16}>
                            <Space direction="vertical" size={0}>
                                <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Amount</Typography.Text>
                                <Typography.Text className="muli light fs-18px dark-green"><Image src={FLAGS[currency]} preview={false} width={20} /> {currency} {amount}</Typography.Text>
                            </Space>
                        </Col>
                        <Col span={8}>
                            <Space direction="vertical" size={0}>
                                <Typography.Text className="muli semi-bold fs-18px dark-green">Description</Typography.Text>
                                <Typography.Text className="muli light fs-18px dark-green">{description}</Typography.Text>
                            </Space>
                        </Col>
                    </Row>
                    <Row className="m-t-10" gutter={16}>
                        <Col span={24}>
                            <Space direction="vertical" size={0}>
                                <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Method</Typography.Text>
                                <Typography.Text className="muli light fs-18px dark-green">{fundingMethod}</Typography.Text>
                            </Space>
                        </Col>
                    </Row>
                    <Form
                        layout="vertical"
                        onFinish={submitFundingRequest}
                        form={modalForm}
                    >
                        <Row className="m-t-20" gutter={16}>
                            <Col span={24} className='center-align-text'>
                                <Space size={108}>
                                    <Button type="primary" onClick={toggleModal}>Cancel</Button>
                                    <Button type="primary" htmlType="submit" loading={loadingFund}>Confirm</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </>
        ),
        (
            <>
                <Row>
                    <Col span={24}>
                        <Typography.Text type="success" className="medium fs-18px center-align-text">{message}</Typography.Text>
                    </Col>
                </Row>
                <Row className="m-t-10">
                    <Col span={24} className='bg-light-grey rounded p-10'>
                        <Row>
                            <Col span={16}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Amount</Typography.Text>
                                    <Typography.Text className="muli light fs-18px dark-green"> <Image src={FLAGS[currency]} preview={false} width={20} /> {currency} {amount}</Typography.Text>
                                </Space>
                            </Col>
                            <Col span={8}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Description</Typography.Text>
                                    <Typography.Text className="muli light fs-18px dark-green">{description}</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                        <Row className="m-t-10">
                            <Col span={24}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Method</Typography.Text>
                                    <Typography.Text className="muli light fs-18px dark-green">{fundingMethod}</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="m-t-20">
                    <Col span={24} className='center-align-text'>
                        <Space size={108}>
                            <Button type="primary" onClick={() => { toggleModal(); navigate(URLS.WalletDashboard) }}>Dashboard</Button>
                            <Button type="primary" onClick={() => { setState({ contentIndex: 0 }); toggleModal(); navigate(URLS.FundWallet) }}>Fund More</Button>
                        </Space>
                    </Col>
                </Row>
            </>
        ),
        (
            <>
                <Row>
                    <Col span={24}>
                        <Typography.Text type="success" className="medium fs-18px center-align-text">{message}</Typography.Text>
                    </Col>
                </Row>
                <Row className="m-t-10">
                    <Col span={24} className='bg-light-grey rounded p-10'>
                        <Row>
                            <Col span={16}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Amount</Typography.Text>
                                    <Typography.Text className="muli light fs-18px dark-green"> <Image src={FLAGS[currency]} preview={false} width={20} /> {currency} {amount}</Typography.Text>
                                </Space>
                            </Col>
                            <Col span={8}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Description</Typography.Text>
                                    <Typography.Text className="muli light fs-18px dark-green">{description}</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                        <Row className="m-t-10">
                            <Col span={24}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Method</Typography.Text>
                                    <Typography.Text className="muli light fs-18px dark-green">{fundingMethod}</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="m-t-20">
                    <Col span={24} className='center-align-text'>
                        <Space size={108}>
                            <Button type="primary" onClick={() => { toggleModal() }}>Cancel</Button>
                            <Button type="primary" onClick={handleEasyTransfer}>Confirm</Button>
                        </Space>
                    </Col>
                </Row>
            </>
        )
    ]
    useEffect(() => {
        setState({
            fundingHistoryLoading: true,
            volopaAccountHolderName: undefined,
            volopaAccountSortCode: undefined,
            volopaAccountNumber: undefined,
            volopaAccountCountry: undefined,
            volopaAccountAddress: undefined,
            volopaAccountName: undefined,
            volopaAccountPaymentRef: undefined,
            volopaAccountIban: undefined,
            volopaAccountSwift: undefined,
            volopaAccountNumberType: undefined,
            volopaAccountSortCodeLabel: 'Sort Code',
            volopaAccountDetails: undefined,
            fundingLimitReached: false,
            loadingFundingData: false,
        });
        getFundingHistory();
    }, []);

    const confirmSubmit = (key) => {
        setState({
            amount: fundingHistory[key].amount,
            description: fundingHistory[key].description,
            currency: fundingHistory[key].currency,
            fundingMethod: fundingHistory[key].fundingMethodId === OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS ? 'Manual Push Funds' :
                fundingHistory[key].fundingMethodId === OPT_FUNDING_METHOD.EASY_TRANSFER ? 'Easy Transfer' : 'Volopa Collection Account',
            admin: fundingHistory[key].admin,
            fundingIndex: fundingHistory[key],
            contentIndex: fundingHistory?.[key]?.fundingMethodId === OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS || fundingHistory?.[key]?.fundingMethodId === OPT_FUNDING_METHOD.VOLOPA_COLLECTION_ACCOUNT ? 0 : 2,
        });
        toggleModal();
        getPaymentDetails(fundingHistory[key].currency);
    };

    const searchInputChanged = (event) => {
        search.current = event.target.value;
        getFundingHistory();
    };

    const getFundingHistory = async () => {
        setState({ fundingHistoryLoading: true });
        setState({ fundingHistory: undefined });
        let requestUrl = 'client/cw/fund?$with=optCompanyWalletFundStatus,user,tccTransfer&$limit=-1';
        if (dateFilter.current) {
            requestUrl += '&fromDate=' + dateFilter.current + ' 00:00:00&toDate=' + dateFilter.current + ' 23:59:59';
        }
        if (search.current) {
            requestUrl += '&search=%' + search.current + '%';
        }
        requestUrl += '&$pageCount&$skip=' + (currentPage.current - 1) * limit.current + '&$limit=' + limit.current;
        let response = await apiRequest(requestUrl);
        setState({ totalPages: Math.ceil(response.pager.count) });
        if (response && response.data && response.data.length > 0) {
            response = response.data.map((val, key) => {
                val.key = key;
                val.admin = val.user.email;
                val.status = val.opt_company_wallet_fund_status.option.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                val.fundingMethodId = val.fund_method_id;
                val.receiptDate = val?.tcc_transfer?.completed_at && moment.utc(val.tcc_transfer.completed_at).format('DD-MM-YYYY');
                val.submissionDate = moment.utc(val.create_time).format('DD-MM-YYYY');
                return val;
            })
            setState({ fundingHistory: response });
        }
        setState({ fundingHistoryLoading: false });
    }

    return (
        <>
            <Modal
                open={showModal}
                onCancel={toggleModal}
                closable={false}
                footer={null}
                bodyStyle={{ padding: '0px' }}
                destroyOnClose>
                <Card.Grid className="full-percent-width rounded">
                    {modalContent[contentIndex]}
                </Card.Grid>
            </Modal>
            <Row>
                <Col span={24}>
                    <PageDescription {...pageDescriptionProps} />
                </Col>
            </Row>
            <Row className='m-t-10'>
                <Col xxl={4}>
                    <Input.Search placeholder='Search' onChange={debounce(searchInputChanged, 1000)} />
                </Col>
            </Row>
            <Row className='m-t-10'>
                <Col span={24}>
                    <CustomTable
                        columns={columns}
                        className="pointer"
                        onRow={(record) => ({
                            onClick: () => navigate(`${URLS.FundingDetails}/${record.id}`)
                        }
                        )}
                        dataSource={fundingHistory}
                        loading={fundingHistoryLoading || accountStatusFetching}
                    />
                    <Pagination
                        style={paginationStyles}
                        onChange={(page, pageSize) => handlePageChange(page, pageSize)}
                        onShowSizeChange={(current, size) => handlePageSizeChange(current, size)}
                        pageSize={limit.current}
                        total={totalPages}
                        current={currentPage.current}
                    />
                </Col>
            </Row>
        </>
    );
}

export default FundingHistory;
