import { Card, Carousel, Col, Row, Space, Typography, Grid, Image, Spin } from "antd";
import TotalCompanyBalance from "App/Components/TotalCompanyBalance";
import { FLAGS } from "Constants/Images";
import { useRef } from "react";
import { AiFillRightCircle, AiOutlineLeftCircle } from "react-icons/ai";
import { accountingFormat } from "Utils";

const { useBreakpoint } = Grid;

function DashboardCarousel({ cards, showTotals = true, balances = [], balancesLoading = false }) {
  const slider = useRef();
  const breakpoint = useBreakpoint();
  const slidesToShow = (breakpoint.lg === false ? (breakpoint.sm === false ? 1 : 2) : 3);
  const emptySlides = Array.from(Array(cards.length - slidesToShow < 0 ? slidesToShow - cards.length : 0));
  return (
    <>
      <Row>
        <Col span={24}>
          <Card className="bg-gradient big-rounded dashboard-carousel" data-testid='container' bodyStyle={{ padding: '1.25vw' }}>
            {showTotals &&
              (!balances?.length ?
                <TotalCompanyBalance /> :
                <Spin spinning={balancesLoading}>
                  <Row style={{ marginRight: '4.17vw', marginLeft: '4.17vw' }} gutter={[0, 24]}>
                    {balances?.map((balance, key) =>
                      <Col xxl={7} xl={7} lg={7} md={24} sm={24} xs={24} key={key}>
                        <Row justify={breakpoint.lg === false ? 'start' : 'end'}>
                          <Col>
                            <Typography.Text className='dark-green medium fs-28px'>{balance?.currency} Balance</Typography.Text>
                          </Col>
                        </Row>
                        <Row justify={breakpoint.lg === false ? 'start' : 'end'}>
                          <Col>
                            <Space>
                              {FLAGS?.[balance?.currency] &&
                                <Image
                                  src={FLAGS?.[balance?.currency]}
                                  preview={false}
                                  width={36}
                                  alt={balance?.currency}
                                />
                              }
                              <Typography.Text className=' bold fs-28px'>{accountingFormat(balance?.amount) ?? '-'}</Typography.Text>
                            </Space>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Spin>
              )}

            {!!cards && (
              <>
                <Row justify="end" className="m-t-20" style={{ marginRight: '4.17vw' }}>
                  {cards.length > slidesToShow && (
                    <Col className='right-align-text m-r-12'>
                      <Space size='middle' className='dark-green' style={{ fontSize: '15px' }}>
                        <AiOutlineLeftCircle onClick={() => slider.current.prev()} size={18} className='pointer' />
                        <AiFillRightCircle size={18} onClick={() => slider.current.next()} className='pointer' data-testid='button' />
                      </Space>
                    </Col>
                  )}
                </Row>
                <Row className='m-b-40' style={{ overflow: 'hidden', marginRight: '4.17vw', marginLeft: '4.17vw' }}>
                  <Col style={{ minWidth: '250px' }} span={24}>
                    <Carousel
                      slidesToShow={slidesToShow}
                      ref={ref => { slider.current = ref }}
                      dots={false}
                    >
                      {(slidesToShow > cards?.length ? [...cards, ...emptySlides] : cards).map((item, key) => (
                        <div key={key}>
                          {!!item &&
                            <div className="m-t-10 m-r-12 m-b-12 m-l-10">
                              <Card hoverable className="b-g center-align-text hover-no-border" {...item?.cardProps} bodyStyle={{ padding: '1.25vw' }}>
                                <Space direction="vertical">
                                  {item?.image}
                                  <Typography.Text className="muli semi-bold fs-18px">{item?.text}</Typography.Text>
                                </Space>
                              </Card>
                            </div>
                          }
                        </div>
                      ))}
                    </Carousel>
                  </Col>
                </Row>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default DashboardCarousel;
