/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { AiOutlineSync } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Button, Col, Divider, Image, Row, Space, Typography, notification } from "antd";
import getSymbolFromCurrency from "currency-symbol-map";
import { FLAGS } from "Constants/Images";
import AlertsCarousel from "App/Components/AlertsCarousel";
import DashboardCarousel from "App/Components/DashboardCarousel";
import carousel5 from 'Assets/Images/carousel5.png';
import carousel6 from 'Assets/Images/carousel6.png';
import carousel7 from 'Assets/Images/carousel7.png';
import { FaCircle } from "react-icons/fa";
import CustomTable from "App/Components/CustomTable";
import URLS from "Routes/constants";
import { useApiRequest } from "Hooks/API";
import { usePaymentsDashboardState } from "App/Pages/Payments/PaymentsDashboard/store";
import { accountingFormat } from "Utils";
import { useNewPaymentState } from "App/Pages/Payments/NewPayment/store";

function PaymentsDashboard() {
    const setState = usePaymentsDashboardState((state) => state.setState);
    const setnewPaymentState = useNewPaymentState((state) => state.setState);
    const frequentRecipientsData = usePaymentsDashboardState((state) => state.frequentRecipientsData);
    const recentPaymentsData = usePaymentsDashboardState((state) => state.recentPaymentsData);
    const recipientsLoading = usePaymentsDashboardState((state) => state.recipientsLoading);
    const paymentsLoading = usePaymentsDashboardState((state) => state.paymentsLoading);

    const navigate = useNavigate();
    const apiRequest = useApiRequest();

    //
    useEffect(() => {
        getFrequentReciepients();
        getRecentPayments();
    }, []);

    //
    const dashboardCarouselCards = [
        {
            image: <Image src={carousel5} preview={false} height={170} />,
            text: 'Add A Recipient',
            cardProps: {
                onClick: () => navigate(URLS.AddRecipient)
            }
        },
        {
            image: <Image src={carousel6} preview={false} height={170} />,
            text: 'New Payment',
            cardProps: {
                onClick: () => navigate(URLS.NewPayment)
            }
        },
        {
            image: <Image src={carousel7} preview={false} height={170} />,
            text: 'New Batch Payments',
            cardProps: {
                onClick: () => navigate(URLS.BatchPayments)
            }
        },
    ];

    //
    const frequentRecipients = {
        columns: [
            {
                title: 'Recipient',
                dataIndex: 'recipient',
                key: 'recipient',
            },
            {
                title: 'Currency',
                dataIndex: 'currency',
                key: 'currency',
                render: (text) => (
                    <Space size={4}>
                        {FLAGS[text] && <Image src={FLAGS[text]} preview={false} width={30} alt={text} />}
                        {text}
                    </Space>
                )
            },
            {
                title: 'Recipient Type',
                dataIndex: 'type',
                key: 'type',
            },
            {
                title: '',
                dataIndex: 'country',
                key: 'country',
            },
            {
                title: 'Last Payment',
                dataIndex: 'lastPayment',
                key: 'lastPayment',
            },
            {
                title: 'Last Payment Amount',
                dataIndex: 'lastPaymentAmount',
                key: 'lastPaymentAmount',
                render: (val, record) => (
                    getSymbolFromCurrency(record.currency) ?
                        getSymbolFromCurrency(record.currency) + accountingFormat(val) :
                        record.currency + ' ' + accountingFormat(val))
            },
            {
                title: '',
                dataIndex: 'key',
                key: 'action',
                align: 'right',
                render: (text, obj) => <Button type="primary" onClick={() => paySpecificRecipient(obj)}>Pay</Button>
            }
        ],
        data: [
            {
                key: 0,
                recipient: 'Allan Reyman',
                currency: 'GBP',
                type: 'Individual',
                country: 'UK',
                lastPayment: 'June 12, 2021',
                lastPaymentAmount: '1,234'
            },
            {
                key: 1,
                recipient: 'Murry Bigshaw',
                currency: 'GBP',
                type: 'Individual',
                country: 'UK',
                lastPayment: 'April 21, 2021',
                lastPaymentAmount: '2,530'
            },
            {
                key: 2,
                recipient: 'Theo Smith',
                currency: 'GBP',
                type: 'Individual',
                country: 'UK',
                lastPayment: 'December 1, 2021',
                lastPaymentAmount: '1,352.40'
            },
            {
                key: 3,
                recipient: 'Elaine Simmone',
                currency: 'EUR',
                type: 'Individual',
                country: 'UK',
                lastPayment: 'April 21, 2021',
                lastPaymentAmount: '3,604'
            },
            {
                key: 4,
                recipient: "Joe's Repair LTD",
                currency: 'GBP',
                type: 'Company',
                country: 'USA',
                lastPayment: 'April 21, 2021',
                lastPaymentAmount: '1,750'
            },
        ]
    };

    //
    const recentPayments = {
        columns: [
            {
                title: 'Recipient',
                dataIndex: 'recipient',
                key: 'recipient',
            },
            {
                title: 'Sent to Volopa',
                dataIndex: 'sentToVolopa',
                key: 'sentToVolopa',
                render: (text, obj, key) => (
                    <Space size={4}>
                        {FLAGS[obj.sentToVolopaCurrency] && <Image src={FLAGS[obj.sentToVolopaCurrency]} preview={false} width={30} alt={text} />}
                        {accountingFormat(text)}
                    </Space>
                )
            },
            {
                title: 'Paid to Recipient',
                dataIndex: 'paidToRecipient',
                key: 'paidToRecipient',
                render: (text, obj, key) => (
                    <Space size={4}>
                        {FLAGS[obj.paidToRecipientCurrency] && <Image src={FLAGS[obj.paidToRecipientCurrency]} preview={false} width={30} alt={text} />}
                        {accountingFormat(text)}
                    </Space>
                )
            },
            {
                title: '',
                dataIndex: 'status',
                key: 'status',
                render: (text) => (
                    text === 'complete' ? <Typography.Text type="success">
                        <Space size={10} align='center'>
                            <FaCircle style={{ width: '8px' }} /> Complete
                        </Space>
                    </Typography.Text> :
                        text === 'cancelled' ? <Typography.Text type='danger'>
                            <Space size={10} align='center'>
                                <FaCircle style={{ width: '8px' }} /> Cancelled
                            </Space>
                        </Typography.Text> :
                            text
                )
            },
            {
                title: '',
                key: 'action',
                align: 'right',
                render: (text, obj) => <Button type="primary" onClick={() => repeatPayment(obj)}><Space align="start"><AiOutlineSync size={18} className='m-t-2' />Repeat</Space></Button>
            }
        ],
        data: [
            {
                key: 0,
                recipient: 'Allan Reyman',
                sentToVolopa: '5,423',
                paidToRecipient: '5,100',
                status: 'complete'
            },
            {
                key: 1,
                recipient: 'Murry Bigshaw',
                sentToVolopa: '5,423',
                paidToRecipient: '5,100',
                status: 'complete'
            },
            {
                key: 2,
                recipient: 'Theo Smith',
                sentToVolopa: '5,423',
                paidToRecipient: '5,100',
                status: 'cancelled'
            },
            {
                key: 3,
                recipient: 'Elaine Simmone',
                sentToVolopa: '5,423',
                paidToRecipient: '5,100',
                status: 'complete'
            },
            {
                key: 4,
                recipient: "Joe's Repair LTD",
                sentToVolopa: '5,423',
                paidToRecipient: '5,100',
                status: 'complete'
            },
        ]
    };


    const paySpecificRecipient = (recipient) => {
        setnewPaymentState({ recipient: recipient.fullDetails, resetRecipient: false });
        navigate(URLS.NewPayment);
    }

    const repeatPayment = (payment) => {
        setnewPaymentState({ recipient: payment.tcc_beneficiary_full, resetRecipient: false, presetPayment: payment, repeat: true, repeatFundingMethod: payment?.tcc_quote_b_b_self?.tcc_quote_b_b_self_beneficiary_map[0]?.fund_method_id });
        navigate(URLS.NewPayment);
    }

    const getFrequentReciepients = async () => {
        setState({ recipientsLoading: true });
        let requestUrl = 'tcc/beneficiary/frequent?count=5';
        let response = await apiRequest(requestUrl);
        if (response && response.data && response.data.length >= 0) {
            if (response.data.length !== 0) {
                response = response.data.map((val, key) => {
                    val.key = key;
                    val.type = val.type.slice(0, 1).toUpperCase() + val.type.slice(1, val.type.length);
                    val.lastPayment = moment.utc(val.last_payment.date).format('MMMM D, YYYY');
                    val.lastPaymentAmount = val.last_payment_amount;
                    return val;
                })
                setState({ frequentRecipientsData: response });
            }
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with loading your frequent recipients', placement: 'top' });
        }
        setState({ recipientsLoading: false });
    }

    //
    const getRecentPayments = async () => {
        setState({ paymentsLoading: true });
        let requestUrl = 'tcc/payment/history?$with=All&$limit=5&accepted=1';
        let response = await apiRequest(requestUrl);
        if (response && response.data && response.data.length >= 0) {
            if (response.data.length !== 0) {
                response = response.data.map((val, key) => {
                    val.key = key;
                    val.recipient = val.tcc_beneficiary.name === 'undefined undefined' ? val.tcc_beneficiary_full.beneficiary_company_name : val.tcc_beneficiary.name;
                    val.sentToVolopa = val.tcc_quote_b_b_self.calc_client_send;
                    val.paidToRecipient = val.beneficiary_amt;
                    val.status = val?.client_facing_status ?? (val.payment_status.option === "Paid" || val.payment_status.option === "paid" ? "Payment Complete" : val.payment_status.option.slice(0, 1).toUpperCase() + val.payment_status.option.slice(1, val.payment_status.option.length));
                    val.sentToVolopaCurrency = val.tcc_quote_b_b_self.sell_currency;
                    val.paidToRecipientCurrency = val.beneficiary_ccy;
                    return val;
                })
                setState({ recentPaymentsData: response });
            }
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with loading your payments history', placement: 'top' });
        }
        setState({ paymentsLoading: false });
    }

    //
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xxl={16} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <DashboardCarousel cards={dashboardCarouselCards} showTotals={false} />
                </Col>
                <Col xxl={1} xl={0} lg={0} md={0} sm={0} xs={0}>
                    <Divider type='vertical' style={{ height: '100%', margin: '0 50%' }} />
                </Col>
                <Col xxl={7} xl={9} lg={11} md={14} sm={18} xs={24}>
                    <AlertsCarousel />
                </Col>
            </Row>
            <Row gutter={[64, 24]} className='m-t-40'>
                <Col xxl={12} xl={16} lg={20} md={24} sm={24} xs={24}>
                    <Row align="bottom" justify='space-between' gutter={[16, 16]}>
                        <Col>
                            <Typography.Text className="dark-green fs-25px medium">Frequent Recipients</Typography.Text>
                        </Col>
                        <Col>
                            <Button type="link" className="fs-18px medium pointer" onClick={() => navigate(URLS.RecipientList)}>View All</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <CustomTable
                                columns={frequentRecipients.columns}
                                dataSource={frequentRecipientsData}
                                loading={recipientsLoading}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xxl={12} xl={16} lg={20} md={24} sm={24} xs={24}>
                    <Row align="bottom" justify='space-between' gutter={[16, 16]}>
                        <Col>
                            <Typography.Text className="dark-green fs-25px medium">Recent Payments</Typography.Text>
                        </Col>
                        <Col>
                            <Button type="link" className="fs-18px medium pointer" onClick={() => navigate(URLS.PaymentHistory)}>View All</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <CustomTable
                                columns={recentPayments.columns}
                                dataSource={recentPaymentsData}
                                loading={paymentsLoading}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default PaymentsDashboard;
