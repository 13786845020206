import { useQuery } from "@tanstack/react-query";
import { useLoginStore } from "App/Pages/Login/store";
// import { useCollectionsHistoryState } from "App/Pages/Collections/ColletionsHistory/store";
import { APIROUTES } from "Constants/ApiRoutes";
import { useApiRequest } from "Hooks/API";

export default function useCollectionsHistoryQuery({ currentPage = 1, limit = 10, dateFilter, search, sort }) {
  const apiRequest = useApiRequest();
  const config = useLoginStore(s => s.config);

  const fetchQuery = async () => {
    const fromDate = dateFilter && `${dateFilter} 00:00:00`;
    const toDate = dateFilter && `${dateFilter} 23:59:59`;
    const date = dateFilter ? `&fromDate=${fromDate}&toDate=${toDate}` : '';
    const qSearch = search ? `&search=${search}` : '';
    const qSort = sort ? `&orderByField=${sort}` : '';

    const res = await apiRequest(`${APIROUTES.get.collections}?$with=All&$pageCount&$skip=${(currentPage - 1) * limit}&$limit=${limit}${date}${qSearch}${qSort}`);
    return res || [];
  }

  const query = useQuery({
    queryKey: ['_collections', currentPage, limit, dateFilter, search, sort],
    queryFn: fetchQuery,
    enabled: !!config?.service_statuses?.collections_status
  });

  return query;
}