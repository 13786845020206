/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Divider, Row, Typography, Popconfirm, Button, Space, Modal, Card, Form, Image, notification, Spin } from "antd";
import { useEffect } from "react";

import AlertsCarousel from "App/Components/AlertsCarousel";
import DashboardCarousel from "App/Components/DashboardCarousel";
import RateChecker from "App/Components/RateChecker";
import WalletBreakdown from "App/Components/WalletBreakdown";
import { useLoginStore } from "App/Pages/Login/store";
import { useWalletDashboardState } from "App/Pages/Wallet/WalletDashboard/store";
import { FLAGS } from "Constants/Images";
import CustomTable from "App/Components/CustomTable";
import URLS from "Routes/constants";
import convertBalances from 'Assets/Images/carousel1.png';
import cards from 'Assets/Images/carousel2.png';
import fundWallet from 'Assets/Images/carousel4.png';
import { useRateCheckerState } from "App/Components/RateChecker/store";
import { useConvertBalancesState } from "App/Pages/Wallet/ConvertBalances/store";
import getSymbolFromCurrency from "currency-symbol-map";
import { AiOutlineSync } from 'react-icons/ai';
import { useFundingHistoryState } from "App/Pages/Wallet/FundingHistory/store";
import { useNavigate } from "react-router-dom";
import { useApiRequest } from "Hooks/API";
import CardsDashboardModal from "App/Components/CardsDashboardModal";
import { accountingFormat } from "Utils";
import { APIROUTES } from "Constants/ApiRoutes";
import { OPT_FUNDING_METHOD } from "Constants/Opts";

function WalletDashboard() {
    const currencies = useWalletDashboardState(state => state.currencies);
    const modalTitle = useWalletDashboardState(state => state.modalTitle);
    const modalType = useWalletDashboardState(state => state.modalType);
    const modalVisible = useWalletDashboardState(state => state.modalVisible);
    const rateCheckerLoading = useWalletDashboardState(state => state.rateCheckerLoading);
    const setState = useWalletDashboardState(state => state.setState);
    const setRatecheckerState = useRateCheckerState(state => state.setState);
    const setFundingHistoryState = useFundingHistoryState(state => state.setState);
    const fundingIndex = useFundingHistoryState(state => state.fundingIndex);
    const currency = useFundingHistoryState(state => state.currency);
    const amount = useFundingHistoryState(state => state.amount);
    const description = useFundingHistoryState(state => state.description);
    const fundingMethod = useFundingHistoryState(state => state.fundingMethod);
    const setConvertBalancesState = useConvertBalancesState(state => state.setState);
    const config = useLoginStore(state => state.config);
    const walletBreakdownLoading = useWalletDashboardState(state => state.walletBreakdownLoading);
    const walletBalances = useWalletDashboardState(state => state.walletBalances);
    const balances = useWalletDashboardState(state => state.balances);
    const fundingHistoryLoading = useFundingHistoryState(state => state.fundingHistoryLoading);
    const fundingHistory = useFundingHistoryState(state => state.fundingHistory);
    const showFundRepeatModal = useWalletDashboardState(state => state.showFundRepeatModal);
    const loadingFund = useFundingHistoryState((state) => state.loadingFund);
    const toggleFundRepeatModal = () => setState({ showFundRepeatModal: !showFundRepeatModal });
    const fundingMessage = useFundingHistoryState(state => state.message);
    const contentIndex = useFundingHistoryState(state => state.contentIndex);
    const volopaAccountHolderName = useWalletDashboardState(state => state.volopaAccountHolderName);
    const volopaAccountSortCode = useWalletDashboardState(state => state.volopaAccountSortCode);
    const volopaAccountNumber = useWalletDashboardState(state => state.volopaAccountNumber);
    const volopaAccountCountry = useWalletDashboardState(state => state.volopaAccountCountry);
    const volopaAccountAddress = useWalletDashboardState(state => state.volopaAccountAddress);
    const volopaAccountName = useWalletDashboardState(state => state.volopaAccountName);
    const volopaAccountPaymentRef = useWalletDashboardState(state => state.volopaAccountPaymentRef);
    const volopaAccountIban = useWalletDashboardState(state => state.volopaAccountIban);
    const volopaAccountSwift = useWalletDashboardState(state => state.volopaAccountSwift);
    const volopaAccountNumberType = useWalletDashboardState(state => state.volopaAccountNumberType);
    const volopaAccountSortCodeLabel = useWalletDashboardState(state => state.volopaAccountSortCodeLabel);
    const volopaAccountDetails = useWalletDashboardState(state => state.volopaAccountDetails);
    const fundingLimitReached = useWalletDashboardState(state => state.fundingLimitReached);
    const loadingFundingData = useWalletDashboardState(state => state.loadingFundingData);

    const apiRequest = useApiRequest();
    const navigate = useNavigate();
    const [modalForm] = Form.useForm();

    //
    const dashboardCarouselCards = [
        {
            image: <Image src={convertBalances} preview={false} height={170} />,
            text: 'Convert Balances',
            cardProps: {
                onClick: () => navigate(URLS.ConvertWalletBalances)
            }
        },
        {
            image: <Image src={cards} preview={false} height={170} />,
            text: 'Fund Card',
            cardProps: {
                onClick: () => toggleModal('fund')
            }
        },
        {
            image: <Image src={fundWallet} preview={false} height={170} />,
            text: 'Fund Wallet',
            cardProps: {
                onClick: () => navigate(URLS.FundWallet)
            }
        },
    ];

    const goToFundDetailPage = (fundId) => {
        navigate(`${URLS.FundingDetails}/${fundId}`)
    }

    const columns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (val, record) => (
                <Typography.Text className="pointer" onClick={() => goToFundDetailPage(record.id)}>{record.description}</Typography.Text>
            )
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (val, record) => (
                getSymbolFromCurrency(record.currency) ?
                    <Typography.Text className="pointer" onClick={() => goToFundDetailPage(record.id)}>{getSymbolFromCurrency(record.currency) + accountingFormat(val)}</Typography.Text>
                    :
                    <Typography.Text className="pointer">{record.currency + ' ' + accountingFormat(val)}</Typography.Text>
            )
        },
        {
            title: 'Type',
            key: 'fund_method_id',
            dataIndex: 'fund_method_id',
            render: (val, record) => (
                val ?
                    <Typography.Text className="pointer" onClick={() => goToFundDetailPage(record.id)}>{
                        val === OPT_FUNDING_METHOD.EASY_TRANSFER ?
                            'Easy Transfer' :
                            val === OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS ?
                                'Manual Push Funds' :
                                "Volopa Collection Account"
                    }</Typography.Text>
                    :
                    <Typography.Text className="pointer" onClick={() => goToFundDetailPage(record.id)}>Manual Push Funds</Typography.Text>
            )
        },
        {
            dataIndex: 'key',
            key: 'action',
            align: 'right',
            render: (key) => <Popconfirm title="Are you sure you want to repeat this transaction?" placement="topRight" onConfirm={() => confirmSubmit(key)} okText="Yes" cancelText="No"><Button type='primary'><Space align='start'><AiOutlineSync size={18} className='m-t-2' />Repeat</Space></Button></Popconfirm>,
        },
    ];

    const toggleModal = (type) => {
        let modalTitle = '';
        if (type === 'fund') {
            modalTitle = {
                title: 'Fund Card Selection',
                text: '- Choose a Card you wish to Fund'
            }
        }
        setState({ modalVisible: !modalVisible, modalTitle, modalType: type });
    }

    const submitFundingRequest = async () => {
        setFundingHistoryState({ loadingFund: true });
        const data = {
            currency,
            amount,
            description,
            fund_method_id: fundingIndex.fund_method_id
        };
        data.currency = currency;
        data.amount = amount;
        data.description = description;
        const fundResponse = await apiRequest("tcc/company/wallet/repeat", "POST", data);
        if (fundResponse.response && fundResponse.response.data.error) {
            if (fundResponse.status === 400) {
                setFundingHistoryState({ message: fundResponse.response.data.error.message });
            } else {
                notification.error({ message: 'Error Code: ' + fundResponse.response.data.error.error_number, description: fundResponse.response.data.error.message, placement: 'top' });
            }
            setFundingHistoryState({ loadingFund: false, contentIndex: 1 });
        }
        if (fundResponse.data && fundResponse.data.company_wallet_fund_status_id) {
            if (fundResponse.data.company_wallet_fund_status_id === 1) {
                setFundingHistoryState({ message: 'Pending Funds' });
            }
            if (fundResponse.data.company_wallet_fund_status_id === 2) {
                setFundingHistoryState({ message: 'Funding Cancelled' });
            }
            if (fundResponse.data.company_wallet_fund_status_id === 3) {
                setFundingHistoryState({ message: 'Funding Complete' });
            }
            setFundingHistoryState({ loadingFund: false, contentIndex: 1 });
            getFundingHistory();
        }
    };

    const getPaymentDetails = async (currency = null) => {
        setState({
            loadingFundingData: true
        });
        let response = await apiRequest("tcc/fund/account?currency=" + currency);
        if (response && response.data && response.data.result.funding_accounts?.find(account => account?.payment_type === "regular")) {
            let sortCodeLabelTag = 'Sort Code';
            let routingCodeTypeTag = response.data.result.funding_accounts?.find(account => account?.payment_type === "regular").routing_code_type;
            if (routingCodeTypeTag === 'bic_swift') {
                sortCodeLabelTag = 'SWIFT/BIC'
            }
            if (routingCodeTypeTag === 'ach_routing_number') {
                sortCodeLabelTag = 'Ach Routing Number'
            }
            if (routingCodeTypeTag === 'wire_routing_number') {
                sortCodeLabelTag = 'Wire Routing Number'
            }
            setState({
                volopaAccountHolderName: response.data.result.funding_accounts?.find(account => account?.payment_type === "regular").account_holder_name,
                volopaAccountSortCode: response.data.result.funding_accounts?.find(account => account?.payment_type === "regular").routing_code,
                volopaAccountNumber: response.data.result.funding_accounts?.find(account => account?.payment_type === "regular").account_number,
                volopaAccountCountry: response.data.result.funding_accounts?.find(account => account?.payment_type === "regular").bank_country,
                volopaAccountAddress: response.data.result.funding_accounts?.find(account => account?.payment_type === "regular").bank_address,
                volopaAccountNumberType: response.data.result.funding_accounts?.find(account => account?.payment_type === "regular").account_number_type,
                volopaAccountName: response.data.result.funding_accounts?.find(account => account?.payment_type === "regular").bank_name,
                volopaAccountSortCodeLabel: sortCodeLabelTag,
                volopaAccountDetails: response.data.result
            });
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with loading the Volopa funding details for this currency', placement: 'top' });
        }
        setState({
            loadingFundingData: false
        });
    }
    const updateFundingDetails = (e, over) => {
        let sortCodeLabel = 'Sort Code';
        if (over === false) {
            let routingCodeType = volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").routing_code_type;
            if (routingCodeType === 'bic_swift') {
                sortCodeLabel = 'SWIFT/BIC'
            }
            if (routingCodeType === 'ach_routing_number') {
                sortCodeLabel = 'Ach Routing Number'
            }
            if (routingCodeType === 'wire_routing_number') {
                sortCodeLabel = 'Wire Routing Number'
            }
            setState({
                volopaAccountHolderName: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").account_holder_name,
                volopaAccountSortCode: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").routing_code,
                volopaAccountNumber: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").account_number,
                volopaAccountCountry: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").bank_country,
                volopaAccountAddress: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").bank_address,
                volopaAccountName: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").bank_name,
                volopaAccountPaymentRef: volopaAccountDetails?.short_reference,
                volopaAccountIban: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").iban,
                volopaAccountSwift: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").bic_swift,
                volopaAccountNumberType: volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular").account_number_type,
                volopaAccountSortCodeLabel: sortCodeLabel,
                fundingLimitReached: false
            });
        } else {
            setState({
                volopaAccountHolderName: volopaAccountDetails?.settlement_accounts[0].bank_account_holder_name,
                volopaAccountSortCode: volopaAccountDetails?.settlement_accounts[0].routing_code_value_1,
                volopaAccountNumber: volopaAccountDetails?.settlement_accounts[0].account_number,
                volopaAccountCountry: volopaAccountDetails?.settlement_accounts[0].bank_country,
                volopaAccountAddress: volopaAccountDetails?.settlement_accounts[0].bank_address,
                volopaAccountName: volopaAccountDetails?.settlement_accounts[0].bank_name,
                volopaAccountPaymentRef: volopaAccountDetails?.short_reference,
                volopaAccountIban: volopaAccountDetails?.settlement_accounts[0].iban,
                volopaAccountSwift: volopaAccountDetails?.settlement_accounts[0].bic_swift,
                volopaAccountSortCodeLabel: sortCodeLabel,
                fundingLimitReached: true
            });
        }
    }

    const handleEasyTransfer = async () => {
        try {
            setState({ loadingPayment: true, showModal: false });
            const body = {
                type: "FUND_WALLET",
                amount: amount,
                currency: currency,
                description: description,
                fundMethodId: 2,
            }
            const response = await apiRequest(`${APIROUTES.post.accounting_create_payment}`, 'POST', body);
            if (response.success) {
                setState({ contentIndex: 0, amount: undefined, currency: undefined, description: undefined, loadingFund: false });
                window.location.href = response?.response?.data?.hostedUrl;
            } else {
                notification.error({ message: 'Something went wrong!', placement: 'top' })
            }
        } catch {
            notification.error({ message: 'Something went wrong!', placement: 'top' })
        }
        finally {
            setState({ loadingPayment: false });
        }

    }

    const modalContent = [
        (
            <>
                <Spin spinning={loadingFundingData}>
                    <Row justify="center">
                        <Col>
                            <Typography.Text className="medium fs-18px dark-green">Funding Confirmation</Typography.Text>
                        </Col>
                    </Row>
                    <Row className='m-t-10' gutter={[24, 12]} justify='space-between'>
                        <Col>
                            <Button type='primary' {...(fundingLimitReached === true && { ghost: true })} onClick={(e) => updateFundingDetails(e, false)}>
                                Regular/Faster Payment Up To A Value Of £250,000.00
                            </Button>
                        </Col>
                        <Col>
                            <Button type='primary' {...(fundingLimitReached === false && { ghost: true })} onClick={(e) => updateFundingDetails(e, true)}>
                                Payments Over £250,000.00 Or BACS/CHAPS/SWIFT Payments
                            </Button>
                        </Col>
                    </Row>
                    <Row className="m-t-10">
                        <Col span={24} className='bg-light-grey rounded p-10'>
                            <Row gutter={16}>
                                <Col span={16}>
                                    <Space direction="vertical" size={0}>
                                        <Typography.Text className="muli semi-bold fs-18px dark-green">Account Holder Name</Typography.Text>
                                        <Typography.Text className="muli light fs-18px">{volopaAccountHolderName}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={8}>
                                    <Space direction="vertical" size={0}>
                                        <Typography.Text className="muli semi-bold fs-18px dark-green">{volopaAccountSortCodeLabel}</Typography.Text>
                                        <Typography.Text className="muli light fs-18px">{volopaAccountSortCode}</Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                            {fundingLimitReached &&
                                <Row className='m-t-10'>
                                    <Col span={16}>
                                        <Space direction='vertical' size={0}>
                                            <Typography.Text className='muli semi-bold dark-green fs-18px'>IBAN</Typography.Text>
                                            <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountIban}</Typography.Text>
                                        </Space>
                                    </Col>
                                    <Col span={8}>
                                        <Space direction='vertical' size={0}>
                                            <Typography.Text className='muli semi-bold dark-green fs-18px'>SWIFT/BIC</Typography.Text>
                                            <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountSwift}</Typography.Text>
                                        </Space>
                                    </Col>
                                </Row>
                            }
                            <Row className="m-t-10" gutter={16}>
                                <Col span={16}>
                                    {fundingLimitReached ?
                                        <Space direction="vertical" size={0}>
                                            <Typography.Text className="muli semi-bold fs-18px dark-green">Account Number</Typography.Text>
                                            <Typography.Text className="muli light fs-18px">{volopaAccountNumber}</Typography.Text>
                                        </Space> :
                                        <Space direction="vertical" size={0}>
                                            <Typography.Text className="muli semi-bold fs-18px dark-green">{volopaAccountNumberType === "iban" ? "IBAN" : "Account Number"}</Typography.Text>
                                            <Typography.Text className="muli light fs-18px">{volopaAccountNumber}</Typography.Text>
                                        </Space>
                                    }
                                </Col>
                                <Col span={8}>
                                    {!fundingLimitReached ?
                                        <Space direction='vertical' size={0}>
                                            <Typography.Text className='muli semi-bold dark-green fs-18px'>Bank Country</Typography.Text>
                                            <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountCountry}</Typography.Text>
                                        </Space> :
                                        <Space direction='vertical' size={0}>
                                            <Typography.Text className='muli semi-bold dark-green fs-18px'>Payment Reference</Typography.Text>
                                            <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountPaymentRef}</Typography.Text>
                                        </Space>
                                    }
                                </Col>
                            </Row>
                            <Row className="m-t-10" gutter={16}>
                                <Col span={24}>
                                    <Space direction="vertical" size={0}>
                                        <Typography.Text className="muli semi-bold fs-18px dark-green">Bank Name</Typography.Text>
                                        <Typography.Text className="muli light fs-18px">{volopaAccountName}</Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                            <Row className="m-t-10" gutter={16}>
                                <Col span={24}>
                                    <Space direction="vertical" size={0}>
                                        <Typography.Text className="muli semi-bold fs-18px dark-green">Bank Address</Typography.Text>
                                        <Typography.Text className="muli light fs-18px">{volopaAccountAddress}</Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="m-t-10" gutter={16}>
                        <Col span={16}>
                            <Space direction="vertical" size={0}>
                                <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Amount</Typography.Text>
                                <Typography.Text className="muli light fs-18px dark-green"><Image src={FLAGS[currency]} preview={false} width={20} /> {currency} {amount}</Typography.Text>
                            </Space>
                        </Col>
                        <Col span={8}>
                            <Space direction="vertical" size={0}>
                                <Typography.Text className="muli semi-bold fs-18px dark-green">Description</Typography.Text>
                                <Typography.Text className="muli light fs-18px dark-green">{description}</Typography.Text>
                            </Space>
                        </Col>
                    </Row>
                    <Row className="m-t-10" gutter={16}>
                        <Col span={24}>
                            <Space direction="vertical" size={0}>
                                <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Method</Typography.Text>
                                <Typography.Text className="muli light fs-18px dark-green">{fundingMethod}</Typography.Text>
                            </Space>
                        </Col>
                    </Row>
                    <Form
                        layout="vertical"
                        onFinish={submitFundingRequest}
                        form={modalForm}
                    >
                        <Row className="m-t-20" gutter={16}>
                            <Col span={24} className='center-align-text'>
                                <Space size={108}>
                                    <Button type="primary" onClick={toggleFundRepeatModal}>Cancel</Button>
                                    <Button type="primary" htmlType="submit" loading={loadingFund}>Confirm</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </>
        ),
        (
            <>
                <Row>
                    <Col span={24}>
                        <Typography.Text type="success" className="medium fs-18px center-align-text">{fundingMessage}</Typography.Text>
                    </Col>
                </Row>
                <Row className="m-t-10">
                    <Col span={24} className='bg-light-grey rounded p-10'>
                        <Row>
                            <Col span={16}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Amount</Typography.Text>
                                    <Typography.Text className="muli light fs-18px dark-green"> <Image src={FLAGS[currency]} preview={false} width={20} /> {currency} {amount}</Typography.Text>
                                </Space>
                            </Col>
                            <Col span={8}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Description</Typography.Text>
                                    <Typography.Text className="muli light fs-18px dark-green">{description}</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                        <Row className="m-t-10">
                            <Col span={24}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Method</Typography.Text>
                                    <Typography.Text className="muli light fs-18px dark-green">{fundingMethod === 'easy' ? 'Easy Transfer' : 'Manual Push Funds'}</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="m-t-20">
                    <Col span={24} className='center-align-text'>
                        <Space size={108}>
                            <Button type="primary" onClick={() => { toggleFundRepeatModal(); navigate(URLS.WalletDashboard) }}>Dashboard</Button>
                            <Button type="primary" onClick={() => { toggleFundRepeatModal({ contentIndex: 0 }); toggleFundRepeatModal(); navigate(URLS.FundWallet) }}>Fund More</Button>
                        </Space>
                    </Col>
                </Row>
            </>
        ),
        (
            <>
                <Row>
                    <Col span={24}>
                        <Typography.Text type="success" className="medium fs-18px center-align-text">Fund via Easy Transfer (Open Banking)</Typography.Text>
                    </Col>
                </Row>
                <Row className="m-t-10">
                    <Col span={24} className='bg-light-grey rounded p-10'>
                        <Row>
                            <Col span={16}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Amount</Typography.Text>
                                    <Typography.Text className="muli light fs-18px dark-green"> <Image src={FLAGS[currency]} preview={false} width={20} /> {currency} {amount}</Typography.Text>
                                </Space>
                            </Col>
                            <Col span={8}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Description</Typography.Text>
                                    <Typography.Text className="muli light fs-18px dark-green">{description}</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                        <Row className="m-t-10">
                            <Col span={24}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Method</Typography.Text>
                                    <Typography.Text className="muli light fs-18px dark-green">{fundingMethod}</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="m-t-20">
                    <Col span={24} className='center-align-text'>
                        <Space size={108}>
                            <Button type="primary" onClick={() => { toggleFundRepeatModal() }}>Cancel</Button>
                            <Button type="primary" onClick={handleEasyTransfer}>Confirm</Button>
                        </Space>
                    </Col>
                </Row>
            </>
        )
    ]

    const confirmSubmit = (key) => {
        setFundingHistoryState({
            amount: fundingHistory[key].amount,
            description: fundingHistory[key].description,
            currency: fundingHistory[key].currency,
            fundingMethod: fundingHistory[key].fund_method_id === OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS ? 'Manual Push Funds' : fundingHistory[key].fund_method_id === OPT_FUNDING_METHOD.VOLOPA_COLLECTION_ACCOUNT ? "Volopa Collection Account" : 'Easy Transfer',
            admin: fundingHistory[key].admin,
            fundingIndex: fundingHistory[key],
            contentIndex: fundingHistory[key].fund_method_id === OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS || fundingHistory[key].fund_method_id === OPT_FUNDING_METHOD.VOLOPA_COLLECTION_ACCOUNT ? 0 : 2,
        });
        toggleFundRepeatModal();
        getPaymentDetails(fundingHistory[key].currency);
    };

    useEffect(() => {
        setState({
            rateCheckerLoading: true,
            walletBreakdownLoading: true,
            fundingHistoryLoading: true,
            volopaAccountHolderName: undefined,
            volopaAccountSortCode: undefined,
            volopaAccountNumber: undefined,
            volopaAccountCountry: undefined,
            volopaAccountAddress: undefined,
            volopaAccountName: undefined,
            volopaAccountPaymentRef: undefined,
            volopaAccountIban: undefined,
            volopaAccountSwift: undefined,
            volopaAccountNumberType: undefined,
            volopaAccountSortCodeLabel: 'Sort Code',
            volopaAccountDetails: undefined,
            fundingLimitReached: false,
            loadingFundingData: false,
        });
        setRatecheckerState({
            fromCurrencies: undefined,
            toCurrencies: undefined,
            commonFromCurrencies: undefined,
            commonToCurrencies: undefined,
            rate: undefined,
            rateUid: undefined,
            loadingRate: false,
            loadingConvert: false,
            seconds: undefined,
            selectedFromCurrency: undefined,
            selectedToCurrency: undefined,
            toValue: undefined,
            fromValue: undefined,
            side: undefined,
        });
        setConvertBalancesState({
            rateCheckerMessage: undefined
        });
        getCurrencies();
        getWalletBalances();
        getFundingHistory();
        getWalletSummary();
    }, []);

    useEffect(() => {
        if (currencies && currencies.length > 0 && balances && balances.length > 0) {
            setWalletBalances();
        }
    }, [currencies, balances]);

    const getWalletSummary = async () => {
        const response = await apiRequest('company/wallet/balances');
        if (response && response.data) {
            setState({
                cwSummaryCurrency: response.data.currency,
                cwSummaryTotalCompanyBalance: response.data.total_company_balance,
                cwSummaryWalletBalance: response.data.wallet_balance,
                cwSummaryCardsBalance: response.data.cards_balance
            });
        }
    }

    const getCurrencies = async () => {
        const response = await apiRequest('currency?$limit=500&active=1');
        if (response && response.data) {
            let cur = response.data.filter(data => data.active_card === 1).sort((a, b) => a.currency.localeCompare(b.currency));
            cur = cur.map(val => {
                val.flag = FLAGS[val.currency];
                return val;
            });
            setState({ currencies: cur });
        }
        setState({ rateCheckerLoading: false });
    }

    const getWalletBalances = async () => {
        const response = await apiRequest('account/cw/balance');
        setState({ balances: response && response.data });
    }

    const setWalletBalances = () => {
        if (balances && balances.length > 0) {
            balances?.forEach(val => { if (val.currency_id === config?.mvc_ccy?.base_currency_id) val.sort_order = -1 });
            let availableBalance = balances.filter(data => +data?.available_amount !== 0).sort((a, b) => a.sort_order - b.sort_order);
            availableBalance = [...availableBalance, ...balances.filter(data => Number(data?.available_amount) === 0).sort((a, b) => a.sort_order - b.sort_order)]

            availableBalance = availableBalance.map(balance => {
                let currency = currencies.find(cur => cur.id === balance.currency_id);
                if (currency) {
                    balance.flag = currency.flag;
                    balance.currency = currency.currency;
                }
                return balance
            });
            setState({ walletBalances: availableBalance });
        }
        setState({ walletBreakdownLoading: false });
    }

    const getFundingHistory = async () => {
        let response = await apiRequest('client/cw/fund?$limit=4&$with=user');
        if (response && response.data && response.data.length > 0) {
            response = response.data.map((val, key) => {
                val.key = key;
                val.admin = val.user.email;
                val.fundingMethod = 'Manual Push Funds';
                return val;
            })
            setFundingHistoryState({ fundingHistory: response });
        }
        setFundingHistoryState({ fundingHistoryLoading: false });
    }

    return (
        <>
            <CardsDashboardModal show={modalVisible} title={modalTitle} onToggle={toggleModal} type={modalType} />
            <Modal
                open={showFundRepeatModal}
                onCancel={toggleFundRepeatModal}
                closable={false}
                footer={null}
                bodyStyle={{ padding: '0px' }}
                destroyOnClose>
                <Card.Grid className="full-percent-width rounded">
                    {modalContent[contentIndex]}
                </Card.Grid>
            </Modal>

            <Row justify="space-between">
                <Col xxl={16} xl={24}>
                    <DashboardCarousel cards={dashboardCarouselCards} />
                </Col>
                <Col xxl={1} xl={0} lg={0} md={0} sm={0} xs={0} >
                    <Divider type='vertical' style={{ height: '100%', margin: '0 50%' }} />
                </Col>
                <Col xxl={7} xl={9} lg={12} md={16}>
                    <AlertsCarousel />
                </Col>
                <Col className='m-t-20' xxl={9} xl={12} lg={16} md={20} sm={24} xs={24} >
                    <WalletBreakdown loading={walletBreakdownLoading} balances={walletBalances} />
                </Col>
                <Col className="m-t-20" xxl={6} xl={8} lg={12} md={16} sm={18} xs={24} >
                    <Row>
                        <Col span={24}>
                            <Typography.Text className='dark-green medium fs-25px'>Rate Checker</Typography.Text>
                        </Col>
                    </Row>
                    <RateChecker clear={true} loading={rateCheckerLoading} currencies={currencies} timeOut={config && config.card_expires_in} url={URLS.ConvertWalletBalances} />
                </Col>
                <Col className="m-t-20" xxl={7} xl={12} lg={16} md={18} sm={20} xs={24} >
                    <Row>
                        <Col span={24}>
                            <Typography.Text className='medium fs-25px dark-green'>Recent Funding History</Typography.Text>
                        </Col>
                    </Row>
                    <Row justify="end">
                        <Col>
                            <Button type="link" className='medium fs-18px pointer' onClick={() => navigate(URLS.FundingHistory)}>View All</Button>
                        </Col>
                    </Row>
                    <Row className="m-t-5">
                        <Col span={24}>
                            <CustomTable
                                columns={columns}
                                dataSource={fundingHistory}
                                showHeader={false}
                                loading={fundingHistoryLoading}
                                scroll={{ x: 390 }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default WalletDashboard;
