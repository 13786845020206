/* eslint-disable react-hooks/exhaustive-deps */
import CollapseList from "App/Components/CollapseList";
import { useCollectionsAccountDetailsQuery, useCollectionsBalancesQuery } from "App/Pages/Collections/Accounts/query";
import { useCollectionsAccountsState } from "App/Pages/Collections/Accounts/store";
import { useLoginStore } from "App/Pages/Login/store";
import { useNewPaymentState } from "App/Pages/Payments/NewPayment/store";
import useFundWalletState from "App/Pages/Wallet/FundWallet/store";
import { FUND_WALLET_CURRENCIES } from "Constants/Common";
import { FLAGS } from "Constants/Images";
import { OPT_FUNDING_METHOD } from "Constants/Opts";
import { USER_ROLES } from "Constants/UserRoles";
import { useCheckAccountSuspensionQuery } from "Hooks/Queries";
import URLS from "Routes/constants";
import { accountingFormat } from "Utils";
import { Button, Col, Empty, Image, message, notification, Row, Skeleton, Space, Spin, Tooltip, Typography } from "antd";
import { useEffect } from "react";
import { FaRegCopy } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

export default function Accounts() {
  const accounts = useCollectionsAccountsState(s => s.accounts)
  const activeCurrency = useCollectionsAccountsState(s => s.activeCurrency)
  const setState = useCollectionsAccountsState(s => s.setState);
  const config = useLoginStore(s => s.config);
  const setFundWalletState = useFundWalletState(s => s.setState);
  const setNewPaymentState = useNewPaymentState(s => s.setState);
  const navigate = useNavigate();
  const { data: collectionsBalances, isLoading: balancesLoading } = useCollectionsBalancesQuery();
  const { data: account, isLoading: accountLoading } = useCollectionsAccountDetailsQuery({ currency: activeCurrency });
  const { data: accountStatus, isFetching: accountStatusFetching } = useCheckAccountSuspensionQuery();

  useEffect(() => {
    if (!!accountStatus?.suspended_accounts?.payments) {
      notification.destroy();
      notification.error({
        description: 'This account is currently frozen for administrative reasons. Our team will get in touch with you soon, if needed.',
        placement: 'top'
      })
    }
  }, [accountStatus]);

  useEffect(() => {
    if (collectionsBalances) {
      const accounts = Object.keys(collectionsBalances).sort(
        (a, b) => a?.localeCompare(b)).sort(
          (a, b) => config?.currencies_order?.find(item => item?.currency === a)?.sort_order - config?.currencies_order?.find(item => item?.currency === b)?.sort_order).map(currency => ({
            currency,
            header: <Row gutter={[48, 12]} align="middle" style={{ minHeight: "100px" }}>
              <Col>
                <Space>
                  {FLAGS?.[currency] &&
                    <Image src={FLAGS[currency]} width={36} preview={false} alt={currency} />
                  }
                  <Typography.Text className="fs-18px semi-bold">{currency}</Typography.Text>
                </Space>
              </Col>
              <Col flex="400px">
                <Row justify="end">
                  <Col>
                    <Typography.Text className="fs-18px semi-bold right-align-text">Total Balance: {currency} {accountingFormat(collectionsBalances[currency]?.balance)}</Typography.Text>
                  </Col>
                  {+collectionsBalances[currency]?.booked !== 0 &&
                    <Col>
                      <Typography.Text className="fs-18px semi-bold right-align-text"><Typography.Text type="warning" className="bold">Booked Payment: </Typography.Text>{currency} {accountingFormat(collectionsBalances[currency]?.booked)}</Typography.Text>
                    </Col>
                  }
                </Row>
              </Col>
              <Col>
                <Tooltip
                  title="This account is currently frozen for administrative reasons. Our team will get in touch with you soon, if needed."
                  {...(!accountStatus?.suspended_accounts?.payments && { open: false })}
                >
                  <Button
                    type="primary"
                    onClick={() => { setNewPaymentState({ fundingCurrency: currency, fundFromCollections: true }); navigate(URLS.NewPayment) }}
                    disabled={!!accountStatus?.suspended_accounts?.payments}
                  >
                    Payout
                  </Button>
                </Tooltip>
              </Col>
              {!config.is_eea && FUND_WALLET_CURRENCIES.includes(currency) &&
                <Col>
                  <Tooltip
                    title="This account is currently frozen for administrative reasons. Our team will get in touch with you soon, if needed."
                    {...(!accountStatus?.suspended_accounts?.payments && { open: false })}
                  >
                    <Tooltip
                      title="Your User Profile has not been permitted to access Company Wallet. Please contact your Administrator to grant you the necessary permission"
                      {...((
                        [USER_ROLES.admin.roleId, USER_ROLES.primary_admin.roleId].includes(config.role_id) ||
                        !!accountStatus?.suspended_accounts?.payments
                      ) && { open: false })}
                    >
                      <Button
                        type="primary"
                        onClick={() => { setFundWalletState({ currency, method: OPT_FUNDING_METHOD.VOLOPA_COLLECTION_ACCOUNT }); navigate(URLS.FundWallet) }}
                        disabled={!!accountStatus?.suspended_accounts?.payments || ![USER_ROLES.admin.roleId, USER_ROLES.primary_admin.roleId].includes(config.role_id)}
                      >
                        Fund Card Wallet
                      </Button>
                    </Tooltip>
                  </Tooltip>
                </Col>
              }
            </Row>,
            content:
              <Skeleton active loading={activeCurrency === currency && accountLoading}>
                {account ?
                  <Row gutter={[48, 12]}>
                    {account?.funding_accounts?.find(account => account?.payment_type === "priority") &&
                      <Col flex="750px">
                        <Row justify="space-between m-b-20">
                          <Col>
                            <Typography.Text className="fs-18px semi-bold underline">Priority Account</Typography.Text>
                          </Col>
                          <Col>
                            <Button
                              type="primary"
                              onClick={() => handleCopyToClipboard(
                                `Account Holder Name: ${account?.funding_accounts?.find(account => account?.payment_type === "priority")?.account_holder_name}
${account?.funding_accounts?.find(account => account?.payment_type === "priority")?.routing_code_type === "bic_swift" ? "SWIFT/BIC" :
                                  account?.funding_accounts?.find(account => account?.payment_type === "priority")?.routing_code_type === "ach_routing_number" ? "Ach Routing Number" :
                                    account?.funding_accounts?.find(account => account?.payment_type === "priority")?.routing_code_type === "wire_routing_number" ? "Wire Routing Number" :
                                      account?.funding_accounts?.find(account => account?.payment_type === "priority")?.routing_code_type === "routing_code" ? "Routing Code" :
                                        "Sort Code"}: ${account?.funding_accounts?.find(account => account?.payment_type === "priority")?.routing_code}
${account?.funding_accounts?.find(account => account?.payment_type === "priority")?.account_number_type === "iban" ? "IBAN" : "Account Number"}: ${account?.funding_accounts?.find(account => account?.payment_type === "priority")?.account_number}
Bank Name: ${account?.funding_accounts?.find(account => account?.payment_type === "priority")?.bank_name}
Bank Address: ${account?.funding_accounts?.find(account => account?.payment_type === "priority")?.bank_address}
Bank Country: ${account?.funding_accounts?.find(account => account?.payment_type === "priority")?.bank_country}
`, `Priority Account details`
                              )}
                            >
                              Copy to clipboard
                            </Button>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col>
                            <Typography.Text className="fs-18px semi-bold">Account Holder Name:</Typography.Text>
                          </Col>
                          <Col>
                            <Space>
                              <Typography.Text className="fs-18px regular">{account?.funding_accounts?.find(account => account?.payment_type === "priority")?.account_holder_name}</Typography.Text>
                              <Tooltip title="Copy to clipboard" >
                                <Link className="dark-grey" onClick={() => handleCopyToClipboard(account?.funding_accounts?.find(account => account?.payment_type === "priority")?.account_holder_name, "Account Holder Name")}>
                                  <FaRegCopy size={27} />
                                </Link>
                              </Tooltip>
                            </Space>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col>
                            <Typography.Text className="fs-18px semi-bold">
                              {account?.funding_accounts?.find(account => account?.payment_type === "priority")?.routing_code_type === "bic_swift" ? "SWIFT/BIC" :
                                account?.funding_accounts?.find(account => account?.payment_type === "priority")?.routing_code_type === "ach_routing_number" ? "Ach Routing Number" :
                                  account?.funding_accounts?.find(account => account?.payment_type === "priority")?.routing_code_type === "wire_routing_number" ? "Wire Routing Number" :
                                    account?.funding_accounts?.find(account => account?.payment_type === "priority")?.routing_code_type === "routing_code" ? "Routing Code" :
                                      "Sort Code"}:
                            </Typography.Text>
                          </Col>
                          <Col>
                            <Space>
                              <Typography.Text className="fs-18px regular">{account?.funding_accounts?.find(account => account?.payment_type === "priority")?.routing_code}</Typography.Text>
                              <Tooltip title="Copy to clipboard" >
                                <Link
                                  className="dark-grey"
                                  onClick={() => handleCopyToClipboard(
                                    account?.funding_accounts?.find(account => account?.payment_type === "priority")?.routing_code,
                                    account?.funding_accounts?.find(account => account?.payment_type === "priority")?.routing_code_type === "bic_swift" ? "SWIFT/BIC" :
                                      account?.funding_accounts?.find(account => account?.payment_type === "priority")?.routing_code_type === "ach_routing_number" ? "Ach Routing Number" :
                                        account?.funding_accounts?.find(account => account?.payment_type === "priority")?.routing_code_type === "wire_routing_number" ? "Wire Routing Number" :
                                          account?.funding_accounts?.find(account => account?.payment_type === "priority")?.routing_code_type === "routing_code" ? "Routing Code" :
                                            "Sort Code"
                                  )}
                                >
                                  <FaRegCopy size={27} />
                                </Link>
                              </Tooltip>
                            </Space>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col>
                            <Typography.Text className="fs-18px semi-bold">{account?.funding_accounts?.find(account => account?.payment_type === "priority")?.account_number_type === "iban" ? "IBAN" : "Account Number"}</Typography.Text>
                          </Col>
                          <Col>
                            <Space>
                              <Typography.Text className="fs-18px regular">{account?.funding_accounts?.find(account => account?.payment_type === "priority")?.account_number}</Typography.Text>
                              <Tooltip title="Copy to clipboard" >
                                <Link
                                  className="dark-grey"
                                  onClick={() => handleCopyToClipboard(
                                    account?.funding_accounts?.find(account => account?.payment_type === "priority")?.account_number,
                                    account?.funding_accounts?.find(account => account?.payment_type === "priority")?.account_number_type === "iban" ? "IBAN" : "Account Number"
                                  )}
                                >
                                  <FaRegCopy size={27} />
                                </Link>
                              </Tooltip>
                            </Space>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col>
                            <Typography.Text className="fs-18px semi-bold">Bank Name:</Typography.Text>
                          </Col>
                          <Col>
                            <Space>
                              <Typography.Text className="fs-18px regular">{account?.funding_accounts?.find(account => account?.payment_type === "priority")?.bank_name}</Typography.Text>
                              <Tooltip title="Copy to clipboard" >
                                <Link className="dark-grey" onClick={() => handleCopyToClipboard(account?.funding_accounts?.find(account => account?.payment_type === "priority")?.bank_name, "Bank Name")}>
                                  <FaRegCopy size={27} />
                                </Link>
                              </Tooltip>
                            </Space>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col>
                            <Typography.Text className="fs-18px semi-bold">Bank Address:</Typography.Text>
                          </Col>
                          <Col>
                            <Space>
                              <Typography.Text className="fs-18px regular">{account?.funding_accounts?.find(account => account?.payment_type === "priority")?.bank_address}</Typography.Text>
                              <Tooltip title="Copy to clipboard" >
                                <Link className="dark-grey" onClick={() => handleCopyToClipboard(account?.funding_accounts?.find(account => account?.payment_type === "priority")?.bank_address, "Bank Address")}>
                                  <FaRegCopy size={27} />
                                </Link>
                              </Tooltip>
                            </Space>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col>
                            <Typography.Text className="fs-18px semi-bold">Bank Country:</Typography.Text>
                          </Col>
                          <Col>
                            <Space>
                              <Typography.Text className="fs-18px regular">{account?.funding_accounts?.find(account => account?.payment_type === "priority")?.bank_country}</Typography.Text>
                              <Tooltip title="Copy to clipboard" >
                                <Link className="dark-grey" onClick={() => handleCopyToClipboard(account?.funding_accounts?.find(account => account?.payment_type === "priority")?.bank_country, "Bank Country|")}>
                                  <FaRegCopy size={27} />
                                </Link>
                              </Tooltip>
                            </Space>
                          </Col>
                        </Row>
                      </Col>
                    }
                    {account?.funding_accounts?.find(account => account?.payment_type === "regular") &&
                      <Col flex={"750px"}>
                        <Row justify="space-between m-b-20">
                          <Col>
                            <Typography.Text className="fs-18px semi-bold underline">Local / Regular Account​</Typography.Text>
                          </Col>
                          <Col>
                            <Button
                              type="primary"
                              onClick={() => handleCopyToClipboard(
                                `Account Holder Name: ${account?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_holder_name}
${account?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_number_type === "iban" ? "IBAN" : "Account Number"}: ${account?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_number}
${account?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code_type === "bic_swift" ? "SWIFT/BIC" :
                                  account?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code_type === "ach_routing_number" ? "Ach Routing Number" :
                                    account?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code_type === "wire_routing_number" ? "Wire Routing Number" :
                                      account?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code_type === "routing_code" ? "Routing Code" :
                                        "Sort Code"}: ${account?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code}
Bank Name: ${account?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_name}
Bank Address: ${account?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_address}
Bank Country: ${account?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_country}
`, `Local / Regular Account details`
                              )}
                            >
                              Copy to clipboard
                            </Button>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col>
                            <Typography.Text className="fs-18px semi-bold">Account Holder Name:</Typography.Text>
                          </Col>
                          <Col>
                            <Space>
                              <Typography.Text className="fs-18px regular">{account?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_holder_name}</Typography.Text>
                              <Tooltip title="Copy to clipboard" >
                                <Link
                                  className="dark-grey"
                                  onClick={() => handleCopyToClipboard(
                                    account?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_holder_name,
                                    "Account Holder Name"
                                  )}
                                >
                                  <FaRegCopy size={27} />
                                </Link>
                              </Tooltip>
                            </Space>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col>
                            <Typography.Text className="fs-18px semi-bold">{account?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_number_type === "iban" ? "IBAN" : "Account Number"}:</Typography.Text>
                          </Col>
                          <Col>
                            <Space>
                              <Typography.Text className="fs-18px regular">{account?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_number}</Typography.Text>
                              <Tooltip title="Copy to clipboard" >
                                <Link
                                  className="dark-grey"
                                  onClick={() => handleCopyToClipboard(
                                    account?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_number,
                                    account?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_number_type === "iban" ? "IBAN" : "Account Number"
                                  )}
                                >
                                  <FaRegCopy size={27} />
                                </Link>
                              </Tooltip>
                            </Space>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col>
                            <Typography.Text className="fs-18px semi-bold">
                              {account?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code_type === "bic_swift" ? "SWIFT/BIC" :
                                account?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code_type === "ach_routing_number" ? "Ach Routing Number" :
                                  account?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code_type === "wire_routing_number" ? "Wire Routing Number" :
                                    account?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code_type === "routing_code" ? "Routing Code" :
                                      "Sort Code"}:
                            </Typography.Text>
                          </Col>
                          <Col>
                            <Space>
                              <Typography.Text className="fs-18px regular">{account?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code}</Typography.Text>
                              <Tooltip title="Copy to clipboard" >
                                <Link
                                  className="dark-grey"
                                  onClick={() => handleCopyToClipboard(
                                    account?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code,
                                    account?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code === "bic_swift" ? "SWIFT/BIC" :
                                      account?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code === "ach_routing_number" ? "Ach Routing Number" :
                                        account?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code === "wire_routing_number" ? "Wire Routing Number" :
                                          account?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code_type === "routing_code" ? "Routing Code" :
                                            "Sort Code"
                                  )}
                                >
                                  <FaRegCopy size={27} />
                                </Link>
                              </Tooltip>
                            </Space>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col>
                            <Typography.Text className="fs-18px semi-bold">Bank Name:</Typography.Text>
                          </Col>
                          <Col>
                            <Space>
                              <Typography.Text className="fs-18px regular">{account?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_name}</Typography.Text>
                              <Tooltip title="Copy to clipboard" >
                                <Link
                                  className="dark-grey"
                                  onClick={() => handleCopyToClipboard(
                                    account?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_name,
                                    "Bank Name"
                                  )}
                                >
                                  <FaRegCopy size={27} />
                                </Link>
                              </Tooltip>
                            </Space>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col>
                            <Typography.Text className="fs-18px semi-bold">Bank Adderess:</Typography.Text>
                          </Col>
                          <Col>
                            <Space>
                              <Typography.Text className="fs-18px regular">{account?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_address}</Typography.Text>
                              <Tooltip title="Copy to clipboard" >
                                <Link
                                  className="dark-grey"
                                  onClick={() => handleCopyToClipboard(
                                    account?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_address,
                                    "Bank Address"
                                  )}
                                >
                                  <FaRegCopy size={27} />
                                </Link>
                              </Tooltip>
                            </Space>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col>
                            <Typography.Text className="fs-18px semi-bold">Bank Country:</Typography.Text>
                          </Col>
                          <Col>
                            <Space>
                              <Typography.Text className="fs-18px regular">{account?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_country}</Typography.Text>
                              <Tooltip title="Copy to clipboard" >
                                <Link
                                  className="dark-grey"
                                  onClick={() => handleCopyToClipboard(
                                    account?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_country,
                                    "Bank Country"
                                  )}
                                >
                                  <FaRegCopy size={27} />
                                </Link>
                              </Tooltip>
                            </Space>
                          </Col>
                        </Row>
                      </Col>
                    }
                  </Row> :
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
              </Skeleton>
          }))

      setState({ accounts })
    }
  }, [collectionsBalances, account, accountLoading, activeCurrency, accountStatus])

  const handleActiveKeyChange = (activeCurrency) => {
    setState({ activeCurrency })
  }

  const handleCopyToClipboard = (text, title) => {
    navigator.clipboard.writeText(text);
    message.destroy();
    message.success(`${title || ''} copied to clipboard`);
  }

  return (
    <Spin size="large" spinning={accountStatusFetching}>
      <Row className="m-t-20">
        <Col flex={"1"}>
          <CollapseList listItems={accounts} loading={balancesLoading} rowKey="currency" onActiveKeyChange={handleActiveKeyChange} />
        </Col>
      </Row>
    </Spin>
  )
}