/* eslint-disable react-hooks/exhaustive-deps */
import { Collapse, Empty, Skeleton, Spin, } from "antd";
import { useEffect, useState } from "react";
import { AiFillMinusCircle, AiFillDownCircle } from "react-icons/ai";

export default function CollapseList({ listItems = [], loading = false, rowKey = "", onActiveKeyChange = () => { } }) {
  const [activeKey, setActiveKey] = useState(undefined);

  useEffect(() => {
    onActiveKeyChange(activeKey)
  }, [activeKey])

  return (
    listItems?.length ?
      <Spin size="large" spinning={loading}>
        {
          listItems?.map((item, key) =>
            <Collapse
              bordered={false}
              className={`${key % 2 === 0 ? "bg-gradient" : "bg-extra-light-grey"} big-rounded`}
              expandIconPosition="end"
              expandIcon={({ isActive }) => isActive ?
                <AiFillMinusCircle className="dark-green pointer" size={25} onClick={() => setActiveKey(undefined)} /> :
                <AiFillDownCircle className="dark-green pointer" size={25} onClick={() => setActiveKey(rowKey === "" ? key : item?.[rowKey])} />
              }
              activeKey={(rowKey === "" ? key : item?.[rowKey]) === activeKey ? 0 : 1}
              key={rowKey === "" ? key : item?.[rowKey]}
            >
              <Collapse.Panel
                header={item?.header}
                className="default-cursor"
              >
                {item?.content}
              </Collapse.Panel>
            </Collapse>
          )
        }
      </Spin> :
      <Collapse
        bordered={false}
        className={`bg-gradient big-rounded`}
        collapsible="disabled"
      >
        <Collapse.Panel
          header={
            <Skeleton loading={loading} active>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Skeleton>
          }
          className="default-cursor"
          showArrow={false}
        >
        </Collapse.Panel>
      </Collapse>
  )
}