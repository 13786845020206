import { Outlet, useNavigate } from "react-router-dom";
import { useLoginState, useLoginStore } from "App/Pages/Login/store";
import { Button, Col, Form, Image, InputNumber, message, notification, Row, Select, Space, Spin, Typography } from "antd";
import { accountingFormat, numberFormat } from "Utils";
import { useSelectSearch } from "Hooks/Search";
import { useCountriesQuery, useCurrenciesQuery } from "Hooks/Queries";
import { useEffect, useMemo, useState } from "react";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import FullScreenModal from "App/Components/FullScreenModal";
import { USER_ROLES } from "Constants/UserRoles";
import URLS from "Routes/constants";

const DROPDOWN_CURRENCIES = [
  "GBP",
  "EUR",
  "USD"
]

export default function CollectionsGuard() {
  const config = useLoginStore(s => s.config);
  const filterSelect = useSelectSearch();
  const apiRequest = useApiRequest();
  const { data: countries, isLoading: countriesLoading } = useCountriesQuery();
  const { data: currencies, isLoading: currenciesLoading } = useCurrenciesQuery(true);
  const getAuthHeader = useLoginState(s => s.getAuthHeader);
  const fetchConfig = useLoginState(s => s.fetchConfig);
  const [submitLoading, setSubmitLoading] = useState(false);
  const roleId = useLoginState(state => state.roleId);
  const navigate = useNavigate();

  useEffect(() => {
    roleId === USER_ROLES.business_user?.roleId && !config?.service_statuses?.collections_status && navigate(URLS.PaymentsDashboard);
  })

  const dropdownCurrencies = useMemo(() =>
    currencies?.filter(val => DROPDOWN_CURRENCIES.includes(val?.currency)),
    [currencies])

  const handleSubmit = async (formData) => {
    setSubmitLoading(true);
    const res = await apiRequest(APIROUTES.post.enable_collections, "POST", formData)
    if (res?.data === "Collection enabled successfully.") {
      message.destroy();
      message.success(res?.data);
      const auth = await getAuthHeader();
      await fetchConfig(auth)
    } else if (res?.data && typeof res?.data === "string") {
      notification.destroy();
      notification.error({ message: "Error", description: res?.data, placement: "top" });
    } else {
      notification.destroy();
      notification.error({ message: "Error", description: "Enabling Collections failed", placement: "top" });
    }
    setSubmitLoading(false)
  }

  return (
    <>
      <FullScreenModal
        open={!config?.service_statuses?.collections_status}
        closable={false}
        showNav
      >
        <Spin spinning={countriesLoading || currenciesLoading}>
          <Row justify="center">
            <Col flex={"600px"}>
              <Typography.Title className="dark-green" level={3}>To access collections please fill in the below questions:</Typography.Title>
              <Form layout="vertical" onFinish={handleSubmit} requiredMark={false}>
                <Form.Item label="Expected total monthly payment value" className="m-b-0">
                  <Row gutter={12}>
                    <Col flex={"100px"}>
                      <Form.Item
                        name={"ccy_id"}
                        rules={[{ required: true, message: '' }]}
                      >
                        <Select
                          showSearch
                          filterOption={filterSelect}
                        >
                          {dropdownCurrencies?.map((val) =>
                            <Select.Option value={val.id} key={val.id}>
                              <Space size={4} align="center">
                                {val?.flag &&
                                  <Image
                                    src={val.flag}
                                    preview={false}
                                    width={20}
                                    alt={val?.currency}
                                  />
                                }
                                {val?.currency}
                              </Space>
                            </Select.Option>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col flex={1}>
                      <Form.Item
                        name={"amount"}
                        rules={[{ required: true, message: 'Please enter amount' }]}
                      >
                        <InputNumber
                          formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
                          parser={(val) => numberFormat(val)}
                          className="full-percent-width"
                          controls={false}
                          min={0}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                  name={"countries"}
                  label="Expected Collections countries"
                  rules={[{ required: true, message: 'Please select at least one country' }]}
                >
                  <Select
                    mode="multiple"
                    filterOption={filterSelect}
                  >
                    {countries?.map(country =>
                      <Select.Option value={country.id} key={country.id}>
                        {country.name}
                      </Select.Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={"currencies"}
                  label="Expected Collections currencies"
                  rules={[{ required: true, message: 'Please select at least one currency' }]}
                >
                  <Select
                    mode="multiple"
                    filterOption={filterSelect}
                  >
                    {currencies?.map(val =>
                      <Select.Option value={val.id} key={val.id}>
                        <Space size={4} align="center">
                          {val.flag && (
                            <Image
                              src={val.flag}
                              preview={false}
                              width={20}
                              alt={val.currency}
                            />
                          )}
                          {val.currency}
                        </Space>
                      </Select.Option>
                    )}
                  </Select>
                </Form.Item>
                <Row justify="end">
                  <Col>
                    <Button type="primary" htmlType="submit" loading={submitLoading}>Confirm</Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Spin>
      </FullScreenModal>
      <Outlet />
    </>
  );
}
