export const ATTACHMENT_EXTENSIONS = [
	'jpg', 'jpeg', 'png', 'pdf', 'docx'
];

export const ATTACHMENT_IMG_EXTENSIONS = [
	'jpg', 'jpeg', 'png',
];

export const NOTIFICATIONS = {
	cardTransaction: 1,
	attachReceipt: 2,
	topUpWallet: 4,
	enhancedTransactions: 5,
	internationalPayment: 6,
	newProduct: 7,
	statementsAndReports: 8,
	workflowApprovals: 9,
	formatPushNotification: 20,
	paymentApproval: 21,
	paymentScheduled: 22,
	scheduledPaymentConfirmation: 23,
	paymentReports: 24,
	contactlessLimit: 25,
	manualPayment: 26,
	swipeAndSign: 27,
	dailyTransactionLimit: 28,
	dailySpendLimit: 29,
	collectionsReport: 31
};

export const EEA_EXCLUDED_CCY = [
	'BHD',
	'BGN',
	'CNY',
	'ILS',
	'KWD',
	'OMR',
	'QAR',
	'RUB',
	'THB',
	'UGX',
	'INR',
];

export const REFUND_EEA_EXCLUDED_CCY = [
	'BHD',
	'BGN',
	'CNY',
	'ILS',
	'KWD',
	'OMR',
	'QAR',
	'RUB',
	'THB',
	'UGX',
	'INR',
];

export const NON_EEA_EXCLUDED_CCY = [
	'BGN',
	'RUB',
	'INR',
];

export const REFUND_NON_EEA_EXCLUDED_CCY = [
	'BGN',
	'CNY',
	'RUB',
	'INR',
];

export const PAYMENT_REASONS = [
	{ id: 1, value: 'Payment of Invoice(s)', label: 'Payment of Invoice(s)' },
	{ id: 2, value: 'Internal Payment to Company own account', label: 'Internal Payment to Company own account' },
	{ id: 3, value: 'Payment of Expenses', label: 'Payment of Expenses' },
	{ id: 4, value: 'Payment of Salaries', label: 'Payment of Salaries' },
	{ id: 5, value: 'Payment of Commissions', label: 'Payment of Commissions' },
	{ id: 6, value: 'Disbursement of Dividends', label: 'Disbursement of Dividends' },
	{ id: 7, value: 'Director/Employee loan', label: 'Director/Employee loan' },
	{ id: 8, value: 'Other', label: 'Other (please fill in the Other Reason field below)' },
]

export const PAYMENT_RECIPIENT_TYPES = {
	individual: 'individual',
	company: 'company',
}


// On dev/sandbox 
// Codat uses diff paltform_type name than live/UAT
export const CODAT_ACCOUNTING_PLATFORMS = {
	qb: 'QuickBooks Online',
	qb_sandbox: 'QuickBooks Online Sandbox',
	xero: 'Xero',
	msdyn: 'Dynamics 365 Business Central',
}

export const ALLOWED_COUNTRIES_ALL_CURRENCIES_PAYMENT_PURPOSE_CODE = ['BH', 'AE']
export const ALLOWED_COUNTRIES_LOCAL_CURRENCY_PAYMENT_PURPOSE_CODE = ['CN_CNY', 'IN_INR']
export const ALLOWED_COUNTRIES_LOCAL_CURRENCY_PAYMENT_PURPOSE_CODE_WITH_B2B_B2C = ['IN_INR']
export const INR_ALLOWED_SELL_CURRENCIES = ['USD', 'GBP', 'EUR', 'AUD', 'CAD']

export const FUND_WALLET_CURRENCIES = ['GBP', 'EUR', 'USD'];