import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useCheckAccountSuspensionQuery = () => {
  const apiRequest = useApiRequest();

  // eslint-disable-next-line no-unused-vars
  const fetchQuery = async () => {
    const { data } = await apiRequest(`${APIROUTES.get.suspensions}`);
    return data;
  }

  const query = useQuery({
    queryKey: ['_suspensions'],
    queryFn: fetchQuery,
  });

  return query;
}

export { useCheckAccountSuspensionQuery };