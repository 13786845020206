import create from "zustand";

export const useCollectionsAccountsState = create(set => ({
  accouts: [],
  activeCurrency: undefined,
  setState: obj => set(obj),
  resetState: () => set({
    accounts: [],
    activeCurrency: undefined,
  })
}))