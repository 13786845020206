/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, Col, Image, Modal, Radio, Row, Space, Typography } from "antd"
import CustomTable from "App/Components/CustomTable";
import PageDescription from "App/Components/PageDescription"
import { FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "Constants/Images";
import getSymbolFromCurrency from "currency-symbol-map";
import { accountingFormat } from "Utils";
import URLS from "Routes/constants";
import moment from "moment";
import { useNotificationsState } from "App/Pages/Notifications/store";
import { useEffect } from "react";
import { cloneDeep } from "lodash";
import { useNotificationsQuery } from "App/Pages/Notifications/hooks/useNotificationsQuery";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import CustomPagination from "App/Components/CustomPagination";
import { NOTIFICATIONS } from "Constants/Common";
import { usePrepaidCardsQuery } from "App/Pages/Cards/query";
import { usePersonalCardsDashState } from "App/Pages/Cards/PersonalCardsDashboard/store";

function Notifications() {
  const navigate = useNavigate();
  const notifications = useNotificationsState(state => state.notifications);
  const modalOpen = useNotificationsState(state => state.modalOpen);
  const isDeleting = useNotificationsState(state => state.isDeleting);
  const allSelected = useNotificationsState(state => state.allSelected);
  const selected = useNotificationsState(state => state.selected);
  const currentPage = useNotificationsState(state => state.currentPage);
  const limit = useNotificationsState(state => state.limit);
  const setState = useNotificationsState(state => state.setState);
  const apiRequest = useApiRequest();
  const { data, isLoading, isFetching, refetch } = useNotificationsQuery();

  const { data: cards, isLoading: cardsLoading } = usePrepaidCardsQuery(true, true);
  const setPersonalCardsState = usePersonalCardsDashState((state) => state.setState);

  useEffect(() => { setState({ notifications: data?.data, allSelected: false, selected: false }) }, [data]);

  const handleSelectToggle = (key) => {
    let items = cloneDeep(notifications);
    items[key].select = !items[key].select
    const selected = items.some(item => item?.select)
    const allSelected = items?.every(item => item?.select);
    setState({ notifications: items, allSelected, selected });
  }

  const handleSelectAllToggle = (e) => {
    const checked = e?.target?.checked;
    let items = cloneDeep(notifications);
    items?.forEach(item => item.select = checked);
    setState({
      notifications: items,
      allSelected: checked,
      selected: checked
    });
  }

  const handleDelete = async (key) => {
    setState({ isDeleting: true });
    const items = cloneDeep(notifications);
    await apiRequest(APIROUTES.del.notifications, 'POST', { ids: [items[key]?.id] });
    setState({ isDeleting: false });
    refetch();
  }

  const handleDeleteSelected = async () => {
    setState({ isDeleting: true });
    const items = cloneDeep(notifications);
    const selectedItems = items?.filter(item => item?.select)?.map(item => item.id);
    await apiRequest(APIROUTES.del.notifications, 'POST', { ids: [...selectedItems] });
    setState({ modalOpen: false, selected: false, allSelected: false, isDeleting: false });
    refetch();
  }

  const handleManageCard = (cardId) => {
    const currentCardIdx = cards?.data?.findIndex(card => +card?.prepaid_card_id === +cardId);
    setPersonalCardsState({ currentCardIdx });
    navigate(`${URLS.ManageSpecificCard}/${cardId}`)
  }

  const columns = [
    {
      title: 'Type',
      dataIndex: 'alert_json_object',
      key: 'type',
      align: 'left',
      width: 300,
      render: (item, obj) => (
        [
          NOTIFICATIONS.cardTransaction,
          NOTIFICATIONS.dailySpendLimit,
          NOTIFICATIONS.dailyTransactionLimit,
          NOTIFICATIONS.contactlessLimit,
          NOTIFICATIONS.manualPayment,
          NOTIFICATIONS.swipeAndSign
        ].includes(obj?.platform_alert_format_id) ? (
          <Space size={12}>
            <Image src={IMAGES.card} preview={false} width={66} />
            <Space direction="vertical" size={0}>
              {item?.title}
              {obj?.platform_alert_format_id === NOTIFICATIONS.cardTransaction && <Typography.Text className="bold" type="danger">Declined</Typography.Text>}
            </Space>
          </Space>
        ) :
          [
            NOTIFICATIONS.attachReceipt,
            NOTIFICATIONS.statementsAndReports,
            NOTIFICATIONS.paymentReports,
            NOTIFICATIONS.collectionsReport
          ].includes(obj?.platform_alert_format_id) ? (
            <Space size={12}>
              <Image src={IMAGES.scroll} preview={false} width={66} />
              {item?.title}
            </Space>
          ) :
            obj?.platform_alert_format_id === 4 ? (
              <Space size={12}>
                <Image src={IMAGES.wallet} preview={false} width={66} />
                <Typography.Text className="bold" type="danger">{item?.title}</Typography.Text>
              </Space>
            ) :
              obj?.platform_alert_format_id === 6 ? (
                <Space size={12}>
                  <Image src={IMAGES.arrows} preview={false} width={66} />
                  <Space direction="vertical" size={0}>
                    {item?.title}
                    <Typography.Text className="bold" type="danger">{item?.status?.data}</Typography.Text>
                  </Space>
                </Space>
              ) :
                obj?.platform_alert_format_id === 7 ? (
                  <Space size={12}>
                    <Image src={IMAGES.products} preview={false} width={66} />
                    <Space direction="vertical" size={0}>
                      {item?.title}
                      <Typography.Text className="bold purple">New Service Added!</Typography.Text>
                    </Space>
                  </Space>
                ) :
                  obj?.platform_alert_format_id === NOTIFICATIONS.paymentApproval ? (
                    <Space size={12}>
                      <Image src={IMAGES.stampSolid} preview={false} width={66} />
                      New payment pending your approval
                    </Space>
                  ) : obj?.platform_alert_format_id === NOTIFICATIONS.scheduledPaymentConfirmation ? (
                    <Space size={12}>
                      <Image src={IMAGES.calendar} preview={false} width={66} />
                      Reminder
                    </Space>
                  ) : obj?.platform_alert_format_id === NOTIFICATIONS.paymentScheduled && (
                    <Space size={12}>
                      <Image src={IMAGES.calendar} preview={false} width={66} />
                      {item?.title}
                    </Space>
                  )
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'blank_0',
      width: 50
    },
    {
      title: 'Information',
      dataIndex: 'alert_json_object',
      key: 'information',
      align: 'left',
      width: 500,
      render: (item, obj) => (
        obj?.platform_alert_format_id === 1 ? (
          <Space direction="vertical" size={0}>
            <div>
              Your transaction for <span className='bold'> {item?.ccy_code?.data} {accountingFormat(item?.amount?.data)}</span> at <span className="bold"> {item?.merchant_name?.data} </span> was declined because your card is <Typography.Text className="bold" type="danger"> Frozen</Typography.Text>
            </div>
            <div>
              <span className='bold'>Unfreeze</span> your card and try again
            </div>
          </Space>
        ) :
          obj?.platform_alert_format_id === 2 ? (
            <Space direction="vertical" size={12}>
              <div>
                Your transaction for <span className='bold'>{item?.ccy_code?.data} {accountingFormat(item?.amount?.data)}</span> at <span className="bold">{item?.merchant_name?.data} </span>
                was authorised.
              </div>
              <div>
                Don't forget to <span className='bold'>attach a receipt!</span>
              </div>
            </Space>
          ) :
            obj?.platform_alert_format_id === 4 ? (
              <>Your Company Wallet balance is now below <Typography.Text className="bold">
                {getSymbolFromCurrency(item?.ccy_code?.data)}{accountingFormat(item?.amount?.data)}
              </Typography.Text>
              </>
            ) :
              obj?.platform_alert_format_id === 6 ? (
                <>
                  Your payment to <span className='bold'>{item?.recipient_name?.data}</span> for <span className="bold">{getSymbolFromCurrency(item?.ccy_code?.data)}
                    {accountingFormat(item?.amount?.data)}</span> was <Typography.Text className='bold' type='danger'>{item?.status?.data}.</Typography.Text>
                </>
              ) :
                obj?.platform_alert_format_id === 7 ? (
                  <>
                    Click here to view our new <span className='bold light-green'>{item?.service_name?.data}</span> service.
                  </>
                ) :
                  obj?.platform_alert_format_id === 8 ? (
                    <>
                      Your latest account statement & expense report are now available.
                    </>
                  ) : obj?.platform_alert_format_id === 24 ? (
                    <>
                      Your payments report is available to download.
                    </>
                  ) : obj?.platform_alert_format_id === NOTIFICATIONS.dailySpendLimit ?
                    <>
                      Your transaction for <span className='bold'> {item?.ccy_code?.data} {accountingFormat(item?.amount?.data)}</span> at <span className="bold"> {item?.merchant_name?.data} </span>
                      was declined because you have exceeded your daily spend limit. Please try again later
                    </>
                    : obj?.platform_alert_format_id === NOTIFICATIONS.dailyTransactionLimit ?
                      <>
                        Your transaction for <span className='bold'> {item?.ccy_code?.data} {accountingFormat(item?.amount?.data)}</span> at <span className="bold"> {item?.merchant_name?.data} </span>
                        was declined because you have exceeded your daily transaction limit. Please try again later
                      </>
                      : obj?.platform_alert_format_id === NOTIFICATIONS.contactlessLimit ?
                        <>
                          Your transaction for <span className='bold'> {item?.ccy_code?.data} {accountingFormat(item?.amount?.data)}</span> at <span className="bold"> {item?.merchant_name?.data} </span>
                          was declined because it exceeded the contactless limits. Please try the transaction again with CHIP & PIN.
                        </>
                        : obj?.platform_alert_format_id === NOTIFICATIONS.manualPayment ?
                          <>
                            Your transaction for <span className='bold'> {item?.ccy_code?.data} {accountingFormat(item?.amount?.data)}</span> at <span className="bold"> {item?.merchant_name?.data} </span>
                            was declined because it was processed as a Manual Payment. Please contact your administrator to enable this payment type for you
                          </>
                          : obj?.platform_alert_format_id === NOTIFICATIONS.swipeAndSign ?
                            <>
                              Your transaction for <span className='bold'> {item?.ccy_code?.data} {accountingFormat(item?.amount?.data)}</span> at <span className="bold"> {item?.merchant_name?.data} </span>
                              was declined because it was processed as a Swipe & Sign transaction. Please contact your administrator to enable this payment type for you
                            </>
                            : [
                              NOTIFICATIONS.paymentApproval,
                              NOTIFICATIONS.scheduledPaymentConfirmation,
                              NOTIFICATIONS.paymentApproval,
                              NOTIFICATIONS.collectionsReport
                            ].includes(obj?.platform_alert_format_id) && (
                              item?.title
                            )
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'blank_1',
      width: 100
    },
    {
      title: '',
      dataIndex: 'alert_json_object',
      key: 'action',
      width: 150,
      render: (item, obj) => {
        const button = [
          NOTIFICATIONS.cardTransaction,
          NOTIFICATIONS.dailySpendLimit,
          NOTIFICATIONS.dailyTransactionLimit,
          NOTIFICATIONS.contactlessLimit,
          NOTIFICATIONS.swipeAndSign,
          NOTIFICATIONS.manualPayment
        ].includes(obj?.platform_alert_format_id) ? {
          onClick: () => handleManageCard(item?.card?.data || item?.card_id?.data),
          text: "Manage Card"
        } :
          obj?.platform_alert_format_id === 2 ? {
            onClick: () => navigate(`${URLS.SpecificCardTransaction}/${item?.transaction_id?.data}`),
            text: "Attach Receipt"
          } :
            obj?.platform_alert_format_id === 4 ? {
              onClick: () => navigate(URLS.FundWallet),
              text: "Fund Wallet"
            } :
              obj?.platform_alert_format_id === 6 ? {
                onClick: () => navigate(`${URLS.SpecificPaymentHistory}/${item?.payment_id?.data}`),
                text: "View Details"
              } :
                obj?.platform_alert_format_id === 7 ? {
                  onClock: () => navigate(URLS.AdditionalServices),
                  text: "View Service"
                } :
                  obj?.platform_alert_format_id === 8 ? {
                    onClick: () => navigate(URLS.StatementsAndReports),
                    text: "Download"
                  } :
                    obj?.platform_alert_format_id === 24 ? {
                      onClick: () => navigate(URLS.PaymentReports),
                      text: "Download"
                    } :
                      obj?.platform_alert_format_id === NOTIFICATIONS?.paymentApproval ? {
                        onClick: () => navigate(`${item?.single_payment?.data === "1" ? URLS.NewPayment : URLS.PaymentSummary}/${item?.quote_id?.data}`),
                        text: "Review"
                      } :
                        obj?.platform_alert_format_id === NOTIFICATIONS?.scheduledPaymentConfirmation ||
                          obj?.platform_alert_format_id === NOTIFICATIONS?.paymentScheduled ? {
                          onClick: () => navigate(URLS.DraftPayments),
                          text: "View Draft Payments"
                        } :
                          obj?.platform_alert_format_id === NOTIFICATIONS.collectionsReport ? {
                            onClick: () => navigate(URLS.CollectionReports),
                            text: "Download"
                          } : {
                            hideButton: true
                          }

        return !button?.hidebutton &&
          <Button
            type="primary"
            block
            onClick={() => {
              button?.onClick();
              apiRequest(`${APIROUTES.put.notifications}${obj?.id}`, 'PUT', { acknowledged: 1 });
            }}
          >
            {button?.text}
          </Button>
      }
    },
    {
      title: '',
      dataIndex: '',
      key: 'blank_2',
      width: 200
    },
    {
      title: 'Time',
      dataIndex: 'create_time',
      key: 'time',
      className: 'bold',
      align: 'left',
      render: time => time ? moment(time).fromNow() : '-'
    },
    {
      title: <Space>Select All <Checkbox onChange={handleSelectAllToggle} checked={allSelected} disabled={!notifications || notifications?.length < 1} /></Space>,
      dataIndex: 'select',
      key: 'select',
      width: 200,
      render: (value, _, key) => <Radio checked={value} onClick={() => handleSelectToggle(key)} className='b-2-grey circular p-l-2 p-r-2' />
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'delete',
      render: (val, _, key) => <Button type="primary" danger size="small" onClick={() => handleDelete(key)}>
        <FaTrashAlt style={{ marginBottom: '-2px' }} />
      </Button>
    }
  ];

  return (
    <>
      <Modal
        footer={false}
        destroyOnClose
        closable={false}
        open={modalOpen}
      >
        <Row>
          <Col>
            <Typography.Text className="muli semi-bold fs-18px dark-green">Clear Selected Notifications?</Typography.Text>
          </Col>
        </Row>
        <Row className="m-t-20" justify='center'>
          <Col>
            <Typography.Text className="muli semi-bold fs-16px dark-green">Are you sure you want to delete selected notifications?</Typography.Text>
          </Col>
        </Row>
        <Row className="m-t-20" justify='space-around'>
          <Col>
            <Button type="primary" onClick={() => setState({ modalOpen: false })}>Cancel</Button>
          </Col>
          <Col>
            <Button type="primary" danger onClick={handleDeleteSelected} loading={isDeleting}>Delete</Button>
          </Col>
        </Row>
      </Modal>
      <Row>
        <Col span={24}>
          <PageDescription title="Notifications" />
        </Col>
      </Row>
      <Row justify="end" className="m-t-10">
        <Col>
          <Button type="primary" danger onClick={() => setState({ modalOpen: true })} disabled={!selected}>Clear Selected</Button>
        </Col>
      </Row>
      <Row className="m-t-10">
        <Col span={24}>
          <CustomTable
            columns={columns}
            dataSource={notifications}
            className='spaced-rows'
            headerColor='green'
            rowKey="id"
            rowClassName='regular fs-18px'
            loading={isLoading || isFetching || isDeleting || cardsLoading}
            styleAllRows />
        </Col>
      </Row>
      <Row style={{ marginTop: '-30px' }}>
        <Col span={24}>
          <CustomPagination
            loading={isFetching || isLoading || isDeleting || cardsLoading}
            onPageChange={(page) => setState({ currentPage: page })}
            total={data?.pager?.count ?? 0}
            pageSize={limit}
            current={currentPage}
            onPageSizeChange={(_, size) => setState({ limit: size, currentPage: 1 })}
          />
        </Col>
      </Row>
    </>
  )
}

export default Notifications
