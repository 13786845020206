import { useQuery } from "@tanstack/react-query";
import { useLoginStore } from "App/Pages/Login/store";
// import { useCollectionsHistoryState } from "App/Pages/Collections/ColletionsHistory/store";
import { APIROUTES } from "Constants/ApiRoutes";
import { useApiRequest } from "Hooks/API";

export function useCollectionsBalancesQuery() {
  const apiRequest = useApiRequest();
  const config = useLoginStore(s => s.config);

  const fetchQuery = async () => {

    const { data } = await apiRequest(`${APIROUTES.get.collections_balances}`);
    return data;
  }

  const query = useQuery({
    queryKey: ['_collections_balances'],
    queryFn: fetchQuery,
    enabled: !!config?.service_statuses?.collections_status,
  });

  return query;
}

export function useCollectionsAccountDetailsQuery({ currency = undefined }) {
  const apiRequest = useApiRequest();
  const config = useLoginStore(s => s.config);

  const fetchQuery = async () => {

    const { data } = await apiRequest(`${APIROUTES.get.payment_funding_account}?currency=${currency}`);
    if (!data) {
      const { data } = await apiRequest(`${APIROUTES.get.collections_balance}?currency=${currency}`);
      return data?.result;
    }

    return data?.result;
  }

  const query = useQuery({
    queryKey: ['_funding_account', currency],
    queryFn: fetchQuery,
    enabled: !!config?.service_statuses?.collections_status && !!currency,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return query;
}