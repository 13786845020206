import create from "zustand";

export const useCollectionsDashboardState = create(set => ({
  currencies: [],
  carouselBalances: [
    {
      currency: "GBP",
      amount: undefined,
    },
    {
      currency: "EUR",
      amount: undefined,
    },
    {
      currency: "USD",
      amount: undefined,
    },
  ],
  setState: obj => set(obj),
  resetState: () => set({
    currencies: [],
    carouselBalances: [
      {
        currency: "GBP",
        amount: undefined,
      },
      {
        currency: "EUR",
        amount: undefined,
      },
      {
        currency: "USD",
        amount: undefined,
      },
    ],
  })
}))