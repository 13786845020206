import { Modal, Row, Col, Typography, Button, Card, Space, Divider, Image, Progress, notification, Tooltip } from "antd";
import { usePaymentSummaryState } from "../store";
import { FaInfoCircle, FaRegCalendarAlt, FaRegCheckCircle } from "react-icons/fa";
import { useLoginStore } from "App/Pages/Login/store";
import { accountingFormat } from "Utils";
import { colors } from "Constants/Style";
import { FLAGS } from "Constants/Images";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { usePayMultipleRecipientsState } from "../../store";
import URLS from "Routes/constants";
import { useNavigate, useParams } from "react-router-dom";
import { OPT_FUNDING_METHOD } from "Constants/Opts";


const PaymentStatus = ({ updateFundingDetails, resetData }) => {
    const volopaAccountHolderName = usePaymentSummaryState(state => state.volopaAccountHolderName);
    const volopaAccountSortCode = usePaymentSummaryState(state => state.volopaAccountSortCode);
    const volopaAccountNumber = usePaymentSummaryState(state => state.volopaAccountNumber);
    const volopaAccountCountry = usePaymentSummaryState(state => state.volopaAccountCountry);
    const volopaAccountAddress = usePaymentSummaryState(state => state.volopaAccountAddress);
    const volopaAccountName = usePaymentSummaryState(state => state.volopaAccountName);
    const volopaAccountPaymentRef = usePaymentSummaryState(state => state.volopaAccountPaymentRef);
    const volopaAccountIban = usePaymentSummaryState(state => state.volopaAccountIban);
    const volopaAccountSwift = usePaymentSummaryState(state => state.volopaAccountSwift);
    const volopaAccountNumberType = usePaymentSummaryState(state => state.volopaAccountNumberType);
    const volopaAccountSortCodeLabel = usePaymentSummaryState(state => state.volopaAccountSortCodeLabel);
    const fundingLimitReached = usePaymentSummaryState(state => state.fundingLimitReached);
    const modalOpen = usePaymentSummaryState(state => state.modalOpen);
    const showPaymentsOver = usePaymentSummaryState(state => state.showPaymentsOver);
    const showPaymentsUnder = usePaymentSummaryState(state => state.showPaymentsUnder);
    const modalType = usePaymentSummaryState(state => state.modalType);
    const setState = usePaymentSummaryState(state => state.setState);
    const youSendTotal = usePaymentSummaryState(state => state.youSendTotal);
    const fundingCurrency = usePaymentSummaryState(state => state.fundingCurrency);
    const quoteUuid = usePaymentSummaryState(state => state.quoteUuid);
    const config = useLoginStore(state => state.config);
    const firstDate = usePaymentSummaryState(state => state.firstDate);
    const displayRate = usePaymentSummaryState(state => state.displayRate);
    const selectedRecipients = usePayMultipleRecipientsState(state => state.selectedRecipients);
    const seconds = usePaymentSummaryState(state => state.seconds);
    const totalAMount = usePaymentSummaryState(state => state.totalAMount);
    const displayRateInversed = usePaymentSummaryState(state => state.displayRateInversed);
    const weConvert = usePaymentSummaryState(state => state.weConvert);
    const totalFees = usePaymentSummaryState(state => state.totalFees);
    const fundingMethod = usePaymentSummaryState(state => state.fundingMethod);
    const loadingOpenFunding = usePaymentSummaryState(state => state.loadingOpenFunding);
    const approvalRequired = usePaymentSummaryState(state => state.approvalRequired);
    const scheduled = usePaymentSummaryState(state => state.scheduled);
    const { mapId } = useParams();
    const apiRequest = useApiRequest();
    const navigate = useNavigate();

    const handleEasyTransfer = async () => {
        try {
            setState({ loadingOpenFunding: true });
            const body = {
                type: "BATCH_FUND_TRANSFER",
                amount: youSendTotal,
                currency: fundingCurrency,
                description: selectedRecipients && selectedRecipients[0]?.paymentReference,
                fundMethodId: 2,
                uuid: quoteUuid,
            }
            const response = await apiRequest(`${APIROUTES.post.accounting_create_payment}`, 'POST', body);
            if (response.success) {
                window.location.href = response?.response?.data?.hostedUrl;
            } else {
                notification.error({ message: 'Something went wrong!', placement: 'top' })
            }
        } catch {
            notification.error({ message: 'Something went wrong!', placement: 'top' })
        }
        finally {
            setState({ loadingOpenFunding: false });
        }
    }

    const viewPayment = () => {
        const url = approvalRequired || scheduled ? URLS.DraftPayments : URLS.PaymentHistory;
        navigate(url);
    }

    return (
        <Modal
            open={modalOpen}
            footer={false}
            closable={false}
            onCancel={() => setState({ modalOpen: false })}
            width={1050}
            destroyOnClose
            maskClosable={false}
        >
            <Row justify='center'>
                <Col>
                    {(modalType === 'fundingConfirmation') && (
                        <Typography.Text className='fs-24px medium dark-green'>Payment Confirmation</Typography.Text>
                    )}
                    {modalType === 'pendingFunds' && !approvalRequired && !scheduled &&
                        <Typography.Text type='success' className='fs-24px medium'><FaRegCheckCircle style={{ marginBottom: '-3px' }} /> Payment Booked - </Typography.Text>
                    }
                    {(modalType === 'pendingFunds' && !!approvalRequired) && (
                        <Typography.Text type='warning' className='fs-24px medium'>Submitted for Approval</Typography.Text>
                    )}
                    {modalType === 'pendingFunds' && !approvalRequired && !scheduled && (
                        <Typography.Text type='warning' className='fs-24px medium'>Pending Funds</Typography.Text>
                    )}
                    {modalType === 'pendingFunds' && !approvalRequired && !!scheduled && (
                        <Typography.Text type='success' className='fs-24px medium'>Payment Scheduled</Typography.Text>
                    )}
                </Col>
            </Row>
            <Row className='m-t-10' gutter={[12, 12]} justify='space-between'>
                {showPaymentsUnder && <Col flex='auto'>
                    <Row justify='center'>
                        <Col>
                            <Button
                                size='small'
                                type='primary'
                                className={!fundingLimitReached ? 'bg-light-green b-g medium' : ''}
                                ghost={fundingLimitReached}
                                onClick={(e) => updateFundingDetails(e, false)}
                            >
                                {config.is_eea === 1 ? "Regular/SEPA Payment Up To A Value Of EUR 250,000" : "Regular / Faster Payment Up To A Value Of £250,000.00"}
                            </Button>
                        </Col>
                    </Row>
                </Col>}
                {showPaymentsOver && <Col flex='auto'>
                    <Row justify='center'>
                        <Col>
                            <Button
                                size='small'
                                type='primary'
                                ghost={!fundingLimitReached}
                                className={fundingLimitReached ? 'bg-light-green b-g medium' : ''}
                                onClick={(e) => updateFundingDetails(e, true)}
                            >
                                {config.is_eea === 1 ? "Payments Over EUR 250,00 Or SWIFT/TARGET2 Payments" : "Payments Over £250,000.00 Or BACS/CHAPS/SWIFT Payments"}
                            </Button>
                        </Col>
                    </Row>
                </Col>}
            </Row>
            <Row className='m-t-10' gutter={[24, 12]}>
                <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                    <Card className='bg-light-grey small-rounded' bodyStyle={{ padding: 10 }}>
                        <Row>
                            <Col span={16}>
                                <Space direction='vertical' size={0}>
                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>Account Holder Name</Typography.Text>
                                    <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountHolderName}</Typography.Text>
                                </Space>
                            </Col>
                            <Col span={8}>
                                <Space direction='vertical' size={0}>
                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>{volopaAccountSortCodeLabel}</Typography.Text>
                                    <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountSortCode}</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                        <Row className='m-t-10'>
                            <Col span={16}>
                                {fundingLimitReached ?
                                    <Space direction="vertical" size={0}>
                                        <Typography.Text className="muli semi-bold fs-18px dark-green">Account Number</Typography.Text>
                                        <Typography.Text className="muli light fs-18px">{volopaAccountNumber}</Typography.Text>
                                    </Space> :
                                    <Space direction="vertical" size={0}>
                                        <Typography.Text className="muli semi-bold fs-18px dark-green">{volopaAccountNumberType === "iban" ? "IBAN" : "Account Number"}</Typography.Text>
                                        <Typography.Text className="muli light fs-18px">{volopaAccountNumber}</Typography.Text>
                                    </Space>
                                }
                            </Col>
                            <Col span={8}>
                                {!fundingLimitReached ?
                                    <Space direction='vertical' size={0}>
                                        <Typography.Text className='muli semi-bold dark-green fs-18px'>Bank Country</Typography.Text>
                                        <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountCountry}</Typography.Text>
                                    </Space> :
                                    <Space direction='vertical' size={0}>
                                        <Typography.Text className='muli semi-bold dark-green fs-18px'>Mandatory Funding Reference</Typography.Text>
                                        <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountPaymentRef}</Typography.Text>
                                    </Space>
                                }
                            </Col>
                        </Row>
                        {fundingLimitReached &&
                            <Row className='m-t-10'>
                                <Col span={16}>
                                    <Space direction='vertical' size={0}>
                                        <Typography.Text className='muli semi-bold dark-green fs-18px'>IBAN</Typography.Text>
                                        <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountIban}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={8}>
                                    <Space direction='vertical' size={0}>
                                        <Typography.Text className='muli semi-bold dark-green fs-18px'>SWIFT/BIC</Typography.Text>
                                        <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountSwift}</Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                        }
                        <Row className='m-t-10'>
                            <Col span={24}>
                                <Space size={0} direction='vertical'>
                                    <Typography.Text className="dark-green fs-18px muli semi-bold">Bank Name</Typography.Text>
                                    <Typography.Text className="dark-green fs-18px muli light">{volopaAccountName}</Typography.Text>
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction='vertical' size={0}>
                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>Bank Address</Typography.Text>
                                    <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountAddress}</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                        <Row className='m-t-10 m-b-10'>
                            <Col span={16}>
                                <Space direction='vertical' size={0}>
                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>Funding Amount</Typography.Text>
                                    <Space size='large' wrap>
                                        <Space>
                                            <Image src={FLAGS[fundingCurrency]} alt={fundingCurrency} width={24} preview={false} />
                                            <Typography.Text className='muli fs-18px light dark-green'>
                                                {fundingCurrency}
                                            </Typography.Text>
                                        </Space>
                                        <Typography.Text className='muli fs-18px light dark-green'>
                                            {accountingFormat((parseFloat(youSendTotal)))}
                                        </Typography.Text>
                                    </Space>
                                </Space>
                            </Col>
                        </Row>
                        {fundingLimitReached && <Typography.Text className='muli semi-bold dark-green fs-12px'>*Please note that the Mandatory Funding Reference is required to ensure we identify your funds efficiently.</Typography.Text>}
                        {config.is_eea === 1 && <Row className='m-t-10 m-b-10'><Typography.Text className='muli semi-bold dark-green fs-12px'>Please ensure that you transfer the funds from the bank account which you have verified. Please note that sending the funds from a different bank account may result in the payment being declined.</Typography.Text></Row>}
                    </Card>
                </Col>
                <Col xxl={12} xl={12} lg={14} md={18} sm={24} xs={24}>
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col>
                                    <Space>
                                        <Typography.Text className="dark-green medium fs-18px">Payment Details</Typography.Text>
                                        {/* <FaInfoCircle className="dark-green" /> */}
                                    </Space>
                                </Col>
                            </Row>
                            <Row className='m-t-10' gutter={12}>
                                <Col span={12}>
                                    <Space>
                                        <Typography.Text className='muli semi-bold fs-18px dark-green'>Expected Delivery</Typography.Text>
                                        <FaRegCalendarAlt size={18} className='dark-green' />
                                    </Space>
                                </Col>
                                <Col span={10}>
                                    <Typography.Text className='muli light fs-18px dark-green'>{firstDate}</Typography.Text>
                                </Col>
                            </Row>
                            <Row className='m-t-10' gutter={12} align='middle'>
                                <Col span={12}>
                                    <Space>
                                        <Typography.Text className='muli semi-bold fs-18px dark-green'>Exchange Rate</Typography.Text>
                                        <Tooltip title="Exchange Rate has been rounded to 6 decimal points."><FaInfoCircle className='dark-green' /></Tooltip>
                                    </Space>
                                </Col>
                                <Col span={10}>
                                    <Space direction="vertical" size={0} >
                                        <Typography.Text className='muli semi-bold fs-18px dark-green'>1 {fundingCurrency} = {displayRate} {selectedRecipients && selectedRecipients[0] && selectedRecipients[0].currency}</Typography.Text>
                                        <Typography.Text className='muli semi-bold fs-18px dark-green'>1 {selectedRecipients && selectedRecipients[0] && selectedRecipients[0].currency} = {displayRateInversed} {fundingCurrency}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={2}>
                                    <Space>
                                        <Progress
                                            type="circle"
                                            percent={(seconds / (+config?.card_expires_in + 0.01)) * 100}
                                            width={40}
                                            format={() => `${seconds}s`}
                                        />
                                    </Space>
                                </Col>
                            </Row>
                            <Row className='m-t-20' gutter={12}>
                                <Col span={10}>
                                    <Typography.Text className='muli extra-bold dark-green fs-18px'>Recipients Receive</Typography.Text>
                                </Col>
                                <Col span={8}>
                                    <Typography.Text className='muli extra-bold dark-green fs-18px'>{accountingFormat(totalAMount)}</Typography.Text>
                                </Col>
                                <Col span={6}>
                                    <Typography.Text className='muli extra-bold dark-green fs-18px'>{selectedRecipients && selectedRecipients[0] && selectedRecipients[0].currency}</Typography.Text>
                                </Col>
                            </Row>
                            <Divider style={{ backgroundColor: colors.lightGreen, margin: 0 }} />
                            <Row gutter={12}>
                                <Col span={10}>
                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>We Convert</Typography.Text>
                                </Col>
                                <Col span={8}>
                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>{accountingFormat(weConvert)}</Typography.Text>
                                </Col>
                                <Col span={6}>
                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>{fundingCurrency}</Typography.Text>
                                </Col>
                            </Row>
                            <Divider style={{ backgroundColor: colors.lightGreen, margin: 0 }} />
                            <Row gutter={12}>
                                <Col span={10}>
                                    <Typography.Text type='danger' className='muli semi-bold fs-18px'>Payment Fees</Typography.Text>
                                </Col>
                                <Col span={8}>
                                    <Typography.Text type='danger' className='muli semi-bold fs-18px'>{totalFees}</Typography.Text>
                                </Col>
                                <Col span={6}>
                                    <Typography.Text type='danger' className='muli semi-bold fs-18px'>{fundingCurrency}</Typography.Text>
                                </Col>
                            </Row>
                            <Row className='m-t-20' gutter={12}>
                                <Col span={10}>
                                    <Typography.Text type='danger' className='muli semi-bold fs-18px'>You Send</Typography.Text>
                                </Col>
                                <Col span={8}>
                                    <Typography.Text type='danger' className='muli semi-bold fs-18px'>{accountingFormat(youSendTotal) ?? ''}</Typography.Text>
                                </Col>
                                <Col span={6}>
                                    <Typography.Text type='danger' className='muli semi-bold fs-18px'>{fundingCurrency}</Typography.Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='m-t-20' justify='center'>
                <Col>
                    {modalType === 'pendingFunds' && !approvalRequired && (
                        <Space size={60}>
                            {fundingMethod === OPT_FUNDING_METHOD.EASY_TRANSFER && <Button type='primary' onClick={handleEasyTransfer} loading={loadingOpenFunding}>Fund via Easy Transfer (Open Banking)</Button>}
                            <Button type='primary' ghost onClick={() => viewPayment()}>View Payment</Button>
                            <Button type='primary' ghost onClick={() => navigate(URLS.PayMultipleRecipients)}>New Payment</Button>
                            <Button type='primary' onClick={() => navigate(URLS.PaymentsDashboard)}>Dashboard</Button>
                        </Space>
                    )}
                    {modalType === 'pendingFunds' && !!approvalRequired && (
                        <Space size={60}>
                            <Button type='primary' onClick={() => navigate(URLS.PaymentsDashboard)}>Return to Dashboard</Button>
                            <Button type='primary' ghost disabled>Submit for Approval</Button>
                        </Space>
                    )}
                </Col>
            </Row>
            {(!!approvalRequired || !!mapId || !!scheduled) &&
                <Row justify='center' className='m-t-20'>
                    <Col>
                        {!!approvalRequired && !scheduled &&
                            ((modalType === 'pendingFunds' && !mapId) || (modalType === 'fundingConfirmation' && mapId)) &&
                            <Typography.Text className='fs-18px medium center-align-text' type='warning'>
                                Pending Approval
                            </Typography.Text>
                        }
                        {modalType === 'fundingConfirmation' && !mapId && !scheduled &&
                            <Typography.Text className='fs-18px medium center-align-text' type='danger'>
                                The FX contract for International Payments that are pending approval is not booked.
                                The live FX contract will be displayed to the Approver at the point of approval
                            </Typography.Text>
                        }
                        {!!scheduled &&
                            <Typography.Text className='fs-18px medium center-align-text' type='danger'>
                                NOTE:  Exchange Rate & Amounts are indicative only.
                                Exchange Rates & Amounts are confirmed in time for the scheduled Expected Delivery date.
                            </Typography.Text>
                        }
                    </Col>
                </Row>
            }
        </Modal>
    )
}

export default PaymentStatus;