import { Button, Col, Collapse, Image, Row, Space, Typography } from "antd";
import { FORMATS } from "Constants/Formats";
import { FLAGS } from "Constants/Images";
import { colors } from "Constants/Style";
import moment from "moment";
import { BsFillCircleFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { accountingFormat } from "Utils";

export default function CollectionReceived({ id, alert_json_object, onClear = () => { } }) {
  const status = 'Success';
  const date = alert_json_object?.date?.data;
  const name = alert_json_object?.name?.data;
  const amountReceived = alert_json_object?.amount_received?.data;
  const currencyReceived = alert_json_object?.ccy_received?.data;
  const title = alert_json_object?.title;

  return (
    <Collapse ghost className="bg-white">
      <Collapse.Panel className="full-percent-width-pannel-header" header={
        <>
          <Row align="middle" justify="space-between" gutter={16}>
            <Col>
              <Space align="center">
                <FaUser style={{ marginBottom: '-3px' }} size={64} className="dark-grey" />
                <Space direction="vertical" size={0}>
                  <Typography.Text className="fs-18px medium">{name}</Typography.Text>
                  <Typography.Text className="light-green muli semi-bold fs-18px">{title}</Typography.Text>
                </Space>
              </Space>
            </Col>
            <Col flex='auto' className="right-align-text">
              <Button type="primary" className="right-align-text big-rounded bg-green">
                <Space size='small'>
                  <BsFillCircleFill size={8} color={colors.successDot} />
                  {status}
                </Space>
              </Button>
            </Col>
          </Row>
        </>
      }
        showArrow={false}>
        <Row justify="space-between" gutter={12}>
          <Col>
            <Space direction="vertical" size={0}>
              <Typography.Text className="medium fs-18px">Transaction Date</Typography.Text>
              <Typography.Text className="light-green muli semi-bold fs-18px">
                {date && moment(date).isValid() ? moment(date).format(FORMATS.date) : ''}
              </Typography.Text>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col>
            <Space direction="vertical" size={0}>
              <Typography.Text className="medium fs-18px">You Received</Typography.Text>
              <Space align="center">
                {FLAGS[currencyReceived] && <Image src={FLAGS[currencyReceived]} preview={false} height={36} />}
                <Typography.Text className="light-green muli semi-bold fs-18px">{`${currencyReceived} ${accountingFormat(amountReceived)}`}</Typography.Text>
              </Space>
            </Space>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Button type="primary" onClick={() => onClear(id)}>Clear</Button>
          </Col>
        </Row>
      </Collapse.Panel>
    </Collapse>
  )
}