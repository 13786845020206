import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useCountriesQuery = () => {
  const apiRequest = useApiRequest();

  // eslint-disable-next-line no-unused-vars
  const fetchQuery = async () => {
    const { data } = await apiRequest(`${APIROUTES.get.countries}?$limit=-1`);
    return data;
  }

  const query = useQuery({
    queryKey: ['_countries'],
    queryFn: fetchQuery,
    select: (resData) => {
      return resData
        .filter((data) => data.allowed === 1)
        .sort((a, b) => a.name.localeCompare(b.name));
    }
  });

  return query;
}

export { useCountriesQuery };