import { Modal } from "antd";

function FullScreenModal({
    open = false,
    onCancel = () => { },
    closable = true,
    showNav = false,
    children
}) {
    const style = `
                    .ant-modal-mask{
                        background-color:rgba(233,238,240,0.8);
                        backdrop-filter: blur(5px);
                        ${showNav && "z-index: 4;"}
                    }
                    .ant-modal-content{
                        background-color: transparent;
                        box-shadow: none;
                        min-height: calc(100vh - ${showNav ? "109" : "45"}px)
                    }
                    .ant-modal-body{
                        padding-left: 2.08%;
                        padding-right: 2.08%;
                    }
                    ${showNav && `
                        .ant-modal-wrap{
                            margin-top: 64px;
                        }
                    `}
                `;

    return (
        <Modal
            open={open}
            destroyOnClose
            footer={null}
            width='100vw'
            style={{ top: 20 }}
            onCancel={onCancel}
            data-testid='container'
            closable={closable}
        >
            <style>
                {style}
            </style>
            {children}
        </Modal>
    )
}

export default FullScreenModal;
