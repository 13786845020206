import { Button, Card, Col, Image, Input, notification, Popconfirm, Row, Space, Spin, Tooltip, Typography } from "antd";
import PageDescription from "App/Components/PageDescription";
import { FLAGS } from "Constants/Images";
import { FaFileDownload, FaSyncAlt } from 'react-icons/fa';
import { useSpecificPaymentHistoryState } from "App/Pages/Payments/PaymentHistory/SpecificPaymentHistory/store";
import moment from "moment";
import { useApiRequest, } from "Hooks/API";
import { useEffect } from "react";
import { accountingFormat } from "Utils";
import { useNavigate, useParams } from "react-router-dom";
import URLS from "Routes/constants";
import { useNewPaymentState } from "App/Pages/Payments/NewPayment/store";
import PaymentAttachmentUpload from "App/Pages/Payments/PaymentHistory/SpecificPaymentHistory/PaymentAttachmentUpload";
import { isEqual } from "lodash";
import { usePaymentAttachmentMutation } from "Hooks/Mutations/usePaymentAttachmentMutation";
import { usePaymentFilesState } from "App/Pages/Payments/PaymentHistory/SpecificPaymentHistory/PaymentAttachmentUpload/store";
import { useExtendedApiRequest } from "Hooks/ExtendedAPI";
import { FORMATS } from "Constants/Formats";
import { useSpecificPaymentHistoryQuery } from "App/Pages/Payments/PaymentHistory/SpecificPaymentHistory/query";
import MT103Modal from "./components/MT103Modal";
import { useLoginStore } from "App/Pages/Login/store";
import { APIROUTES } from "Constants/ApiRoutes";
import { OPT_FUNDING_METHOD } from "Constants/Opts";
import { useCheckAccountSuspensionQuery } from "Hooks/Queries";

function SpecificPaymentHistory() {
    const setState = useSpecificPaymentHistoryState((state) => state.setState);
    const setnewPaymentState = useNewPaymentState((state) => state.setState);
    const expenseAttachmentMutation = usePaymentAttachmentMutation(payload => payload);
    const initData = useSpecificPaymentHistoryState((state) => state.initData);
    const note = useSpecificPaymentHistoryState((state) => state.note);
    const openMt103Modal = useSpecificPaymentHistoryState((state) => state.openMt103Modal);
    const mt103details = useSpecificPaymentHistoryState((state) => state.mt103details);
    const hideMt103Button = useSpecificPaymentHistoryState((state) => state.hideMt103Button);
    const activateLoading = useSpecificPaymentHistoryState((state) => state.activateLoading);
    const volopaAccountDetails = useSpecificPaymentHistoryState((state) => state.volopaAccountDetails);
    const fundingLimitReached = useSpecificPaymentHistoryState((state) => state.fundingLimitReached);
    const showPaymentsOver = useSpecificPaymentHistoryState((state) => state.showPaymentsOver);
    const showPaymentsUnder = useSpecificPaymentHistoryState((state) => state.showPaymentsUnder);
    const saveLoading = useSpecificPaymentHistoryState((state) => state.saveLoading);
    const ref = useSpecificPaymentHistoryState((state) => state.ref);
    const fileStore = usePaymentFilesState((state) => state.fileStore);
    const loading = useSpecificPaymentHistoryState((state) => state.loading);
    const fundingDetails = useSpecificPaymentHistoryState((state) => state.fundingDetails);
    const cancelLoading = useSpecificPaymentHistoryState((state) => state.cancelLoading);
    const setPaymentFilesState = usePaymentFilesState((state) => state.setState);
    const { mapId } = useParams();
    const { data, isFetching, refetch } = useSpecificPaymentHistoryQuery(mapId);
    const config = useLoginStore(state => state.config);
    const apiRequest = useApiRequest();
    const navigate = useNavigate();
    const extendedApiRequest = useExtendedApiRequest();
    const { data: accountStatus, isFetching: accountStatusFetching } = useCheckAccountSuspensionQuery();

    let curSend = data?.tcc_quote_b_b_self?.sell_currency;
    let curRec = data?.beneficiary_ccy;
    let batchId = data?.part_of_batch ? data?.tcc_quote_bb_self_id : '-';
    let allowCancelTrade = false;
    if (data?.deal_data) {
        if ((data?.deal_data?.automatic === null &&
            (data?.deal_data?.deal_summary_status_id === 1 || //SUMMARY_STATUS_PROCESSED
                data?.deal_data?.deal_summary_status_id === 3 || //SUMMARY_STATUS_PENDING
                data?.deal_data?.deal_summary_status_id === 6 || //SUMMARY_STATUS_BENEFICIARY_REQUIRED
                data?.deal_data?.deal_summary_status_id === 7 || //SUMMARY_STATUS_AWAITING_PAYMENT
                data?.deal_data?.deal_summary_status_id === 8)) || //SUMMARY_STATUS_WAITING_FOR_DELIVERY
            (data?.deal_data?.automatic &&
                data?.trade_data?.trade_status_id &&
                data?.deal_data?.automatic === 1 &&
                (data?.trade_data?.trade_status_id === 1 || //TRADE_STATUS_PENDING
                    data?.trade_data?.trade_status_id === 4))) { //TRADE_STATUS_SPLIT

            allowCancelTrade = true;
        }
    }

    useEffect(() => {
        if (!!accountStatus?.suspended_accounts?.payments) {
            notification.destroy();
            notification.error({
                description: 'This account is currently frozen for administrative reasons. Our team will get in touch with you soon, if needed.',
                placement: 'top'
            })
        }
    }, [accountStatus]);

    const fetchFundingDetails = async () => {
        if (curSend) {
            setState({ loading: true })
            let response = await apiRequest("tcc/fund/account/payment?currency=" + curSend);
            if (response && response.data && (response.data.result.funding_accounts?.find(account => account?.payment_type === "regular") || response.data.result.settlement_accounts[0])) {
                setState({
                    volopaAccountDetails: response.data.result,
                    loading: false
                });
                if (response.data.result.settlement_accounts[0]) {
                    updateFundingDetails(null, true, response.data.result);
                }
                if (response.data.result.funding_accounts?.find(account => account?.payment_type === "regular")) {
                    updateFundingDetails(null, true, response.data.result);
                }
                if (response.data.result.funding_accounts?.find(account => account?.payment_type === "regular") && response.data.result.settlement_accounts[0]) {
                    setState({
                        showPaymentsUnder: true,
                        showPaymentsOver: true,
                        fundingLimitReached: true
                    });
                } else {
                    if (response.data.result.settlement_accounts[0]) {
                        setState({
                            showPaymentsOver: true,
                            showPaymentsUnder: false
                        });
                    }
                    if (response.data.result.funding_accounts?.find(account => account?.payment_type === "regular")) {
                        setState({
                            showPaymentsUnder: true,
                            showPaymentsOver: false
                        });
                    }
                }
            }
        }
    }


    const updateFundingDetails = (e, over, details) => {
        let sortCodeLabel = 'Sort Code';
        let volopaAccountDetailsData = details ?? volopaAccountDetails;
        if (over === false) {
            let routingCodeType = volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code_type;
            if (routingCodeType === 'bic_swift') {
                sortCodeLabel = 'SWIFT/BIC'
            }
            if (routingCodeType === 'ach_routing_number') {
                sortCodeLabel = 'Ach Routing Number'
            }
            if (routingCodeType === 'wire_routing_number') {
                sortCodeLabel = 'Wire Routing Number'
            }
            setState({
                fundingDetails: {
                    ...volopaAccountDetails,
                    volopaAccountHolderName: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_holder_name,
                    volopaAccountSortCode: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code,
                    volopaAccountNumber: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_number,
                    volopaAccountCountry: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_country,
                    volopaAccountAddress: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_address,
                    volopaAccountName: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_name,
                    volopaAccountIban: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.iban,
                    volopaAccountSwift: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.bic_swift,
                    volopaAccountNumberType: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_number_type,
                    volopaAccountSortCodeLabel: sortCodeLabel,
                    short_reference: volopaAccountDetailsData?.short_reference
                },
                fundingLimitReached: false

            });
        } else {
            let routingCodeType = volopaAccountDetailsData?.settlement_accounts[0]?.routing_code_type_1;
            if (routingCodeType === 'bic_swift') {
                sortCodeLabel = 'SWIFT/BIC'
            }
            if (routingCodeType === 'ach_routing_number') {
                sortCodeLabel = 'Ach Routing Number'
            }
            if (routingCodeType === 'wire_routing_number') {
                sortCodeLabel = 'Wire Routing Number'
            }
            setState({
                fundingDetails: {
                    ...volopaAccountDetails,
                    volopaAccountHolderName: volopaAccountDetailsData?.settlement_accounts[0]?.bank_account_holder_name,
                    volopaAccountSortCode: volopaAccountDetailsData?.settlement_accounts[0]?.routing_code_value_1,
                    volopaAccountNumber: volopaAccountDetailsData?.settlement_accounts[0]?.account_number,
                    volopaAccountCountry: volopaAccountDetailsData?.settlement_accounts[0]?.bank_country,
                    volopaAccountAddress: volopaAccountDetailsData?.settlement_accounts[0]?.bank_address,
                    volopaAccountName: volopaAccountDetailsData?.settlement_accounts[0]?.bank_name,
                    volopaAccountIban: volopaAccountDetailsData?.settlement_accounts[0]?.iban,
                    volopaAccountSwift: volopaAccountDetailsData?.settlement_accounts[0]?.bic_swift,
                    volopaAccountSortCodeLabel: sortCodeLabel,
                    short_reference: volopaAccountDetailsData?.short_reference
                },
                fundingLimitReached: true
            });
        }
    }

    useEffect(() => {
        return () => {
            setState({ saveLoading: false })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setState({
            fundingLimitReached: false,
            showPaymentsUnder: false,
            showPaymentsOver: false
        });
        fetchFundingDetails()
        // eslint-disable-next-line
    }, [data])

    const readFile = async (file) => {
        return new Promise(function (resolve, reject) {
            var reader = new FileReader();
            reader.onload = (evt) => resolve(evt.target.result);
            reader.onerror = (err) => reject(err)
            reader.readAsDataURL(file);
        });
    }
    const handleMt103Click = async () => {
        try {
            setState({ activateLoading: true })
            const resp = await extendedApiRequest({
                baseUrl: process.env.REACT_APP_AUX_API_BASE_URL,
                path: 'tcc/b2b/payment/submission/' + data?.payment?.tcc_payment_uid,
                method: 'GET',
                onDownloadProgress: () => { }
            });

            if (resp?.data?.success) {
                setState({
                    openMt103Modal: true,
                    mt103details: resp?.data?.response?.mt103 || 'There was an issue retrieving MT103 details for this payment. Please try again and if the problem persists please contact our support team.',
                    hideMt103Button: !resp?.data?.response?.mt103
                })
            } else {
                setState({ openMt103Modal: true, mt103details: 'There was an issue retrieving MT103 details for this payment. Please try again and if the problem persists please contact our support team.', hideMt103Button: true })
            }
        } catch {
            notification.error({ message: 'Something went wrong!', placement: 'top' });
        } finally {
            setState({ activateLoading: false })
        }
    }
    const fetchFiles = async (fileStore) => {
        const filePromises = fileStore.map(async (file) => {
            if (!(file.file_extension.includes('jpg') || file.file_extension.includes('png') || file.file_extension.includes('jpeg'))) {
                return { ...file, type: 'file' };
            }

            const urlRes = await extendedApiRequest({
                path: `file/file?uuid=${file.uuid}`,
                config: { responseType: 'blob' },
                fullResponse: true,
            });

            const thumbRes = await extendedApiRequest({
                path: `file/file/thumbnail?uuid=${file.uuid}`,
                config: { responseType: 'blob' },
                fullResponse: true,
            });

            if (!urlRes || urlRes.data?.error || thumbRes.data?.error) {
                return;
            }

            if (!urlRes.data?.type?.includes('image') || !thumbRes.data?.type?.includes('image')) {
                return;
            }

            const url = await readFile(urlRes.data);
            const thumb = await readFile(thumbRes.data);

            return {
                ...file,
                url: url ?? null,
                thumb: thumb ?? null,
            }
        });
        return await Promise.all(filePromises);
    }

    const goToRecipientDetailPage = (recipientId) => {
        navigate(`${URLS.RecipientDetails}/${recipientId}`)
    }

    const historyData = [
        {
            title: 'ID',
            data: data?.id
        },
        {
            title: 'Batch ID',
            data: batchId !== '-' ?
                <Row justify="space-between">
                    <Col>
                        <Typography>{batchId}</Typography>
                    </Col>
                    <Col>
                        <Tooltip
                            title="This account is currently frozen for administrative reasons. Our team will get in touch with you soon, if needed."
                            {...(!accountStatus?.suspended_accounts?.payments && { open: false })}
                        >
                            <Button
                                type="primary"
                                onClick={() => navigate(`${URLS.PayMultipleRecipients}/${data?.tcc_quote_b_b_self?.uuid}`)}
                                disabled={!!accountStatus?.suspended_accounts?.payments}
                            >
                                <Space>
                                    <FaSyncAlt />
                                    Repeat Batch
                                </Space>
                            </Button>
                        </Tooltip>
                    </Col>
                </Row> : batchId,

        },
        {
            title: 'Submitted',
            data: `${moment(data?.tcc_quote_b_b_self?.accept_time || data?.tcc_quote_b_b_self?.quote_create_time).format(FORMATS.dateTime)} GMT`
        },
        {
            title: 'Status',
            data: !data?.tcc_quote_b_b_self?.accepted ? data?.tcc_quote_b_b_self?.scheduled && 'Scheduled' : (!!data?.client_facing_status ? data?.client_facing_status :
                data?.tcc_quote_b_b_self?.accepted &&
                (data?.payment_status?.option === "Paid" || data?.payment_status?.option === "paid" ? "Payment Complete" : data?.payment_status?.option?.charAt(0)?.toUpperCase() + data?.payment_status?.option?.slice(1))),
            type: (data?.client_facing_status === "Payment Complete" || data?.payment_status?.option === "Paid" || data?.payment_status?.option === "paid") ?
                'success' :
                !data?.tcc_quote_b_b_self?.accepted && data?.tcc_quote_b_b_self?.scheduled ?
                    'warning' :
                    'danger'
        },
        {
            title: 'You Send Us',
            data: <Space size={4}>
                <Image src={FLAGS[curSend]} alt={data?.tcc_quote_b_b_self?.sell_currency} width={24} preview={false} />
                {accountingFormat(data?.tcc_quote_b_b_self?.calc_client_send)}
            </Space>
        },
        {
            title: 'Notional Payment Equivalent',
            data: data?.beneficiary_equivalent_amount ? <Space size={4}>
                <Image src={FLAGS[curSend]} alt={data?.tcc_quote_b_b_self?.sell_currency} width={24} preview={false} />
                {accountingFormat(data?.beneficiary_equivalent_amount)}
            </Space> : '-'
        },
        {
            title: 'Recipient Receives',
            data: <Space size={4}>
                <Image src={FLAGS[curRec]} alt={data?.beneficiary_ccy} width={24} preview={false} />
                {accountingFormat(data?.beneficiary_amt)}
            </Space>
        },
        {
            title: 'Exchange Rate(' + curSend + ':' + curRec + ')',//Exchange Rate(GBP:EUR)'
            data: parseFloat(data?.tcc_quote_b_b_self?.composite_rate)?.toFixed(6) + ' (' + curRec + ': ' + curSend + ') ' + parseFloat(data?.tcc_quote_b_b_self?.composite_rate_inverse).toFixed(6) //'1.177024 (EUR: GBP)0.849618'
        },
        {
            title: 'Settlement Method',
            data: data?.payment_type?.charAt(0)?.toUpperCase() + data?.payment_type?.slice(1) + " (Payment Fee: " + data?.total_fee + ' ' + data?.fee_currency + ')'
        },
        {
            title: 'Expected Delivery Date',
            data: moment(data?.tcc_quote_b_b_self?.delivery_date || data?.tcc_quote_b_b_self?.expected_delivery_date).format(FORMATS.date)
        },
        {
            title: 'Settlement Date',
            data: data?.payment ? moment(data?.payment?.settlement_date).format(FORMATS.date) : '-'
        },
        {
            title: 'Recipient',
            data: data?.tcc_beneficiary?.name === 'undefined undefined'
                ?
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography.Text className="pointer" onClick={() => goToRecipientDetailPage(data?.tcc_beneficiary?.id)}>{data?.tcc_beneficiary_full?.beneficiary_company_name}</Typography.Text>
                    {((
                        (!data?.client_facing_status?.includes("Cancel") || data?.payment_status?.option?.toLowerCase()?.includes("cancel"))
                        && !data?.client_facing_status?.includes("Pending Funds")
                        && !data?.client_facing_status?.includes("Funds Received - Pending Release")
                    ) && data?.payment_type === 'priority' && !!data?.tcc_quote_b_b_self?.accepted && !data?.tcc_quote_b_b_self?.scheduled) && <Button type="primary" onClick={handleMt103Click}>MT103</Button>}
                </div>
                :
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography.Text className="pointer" onClick={() => goToRecipientDetailPage(data?.tcc_beneficiary?.id)}>{data?.tcc_beneficiary?.name}</Typography.Text>
                    {((
                        (!data?.client_facing_status?.includes("Cancel") || data?.payment_status?.option?.toLowerCase()?.includes("cancel"))
                        && !data?.client_facing_status?.includes("Pending Funds")
                        && !data?.client_facing_status?.includes("Funds Received - Pending Release")
                    )
                        && data?.payment_type === 'priority' && !!data?.tcc_quote_b_b_self?.accepted && !data?.tcc_quote_b_b_self?.scheduled) && <Button type="primary" onClick={handleMt103Click}>MT103</Button>}
                </div>
        },
        {
            title: 'Reason For Payment',
            data: data?.payment_reason
        },
        {
            title: 'Payment Reference',
            data: data?.payment_reference
        },
        {
            title: 'Funding Method',
            data: data?.fund_method_id === OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS ?
                'Push Funds' :
                data?.fund_method_id === OPT_FUNDING_METHOD.VOLOPA_COLLECTION_ACCOUNT ?
                    'Volopa Collection Account' :
                    'Easy Transfer'
        },
        /*
        I946 Hide Commission
        {
            title: 'Commission Percentage',
            data: data?.commission_percentage ? data?.commission_percentage + "%" : '-'
        },
        */
    ];

    const submissionData = [
        {
            title: 'Submitting User',
            data: data?.tcc_quote_b_b_self?.user ?
                `${data?.tcc_quote_b_b_self?.user?.profile?.first_name || ''} 
            ${data?.tcc_quote_b_b_self?.user?.profile?.middle_name || ''} 
            ${data?.tcc_quote_b_b_self?.user?.profile?.last_name || ''} 
            (ID: ${data?.tcc_quote_b_b_self?.user?.id || '-'})` :
                '-'
        },
        {
            title: 'Approving User',
            data: data?.tcc_quote_b_b_self?.approved_user ?
                `${data?.tcc_quote_b_b_self?.approved_user?.profile?.first_name || ''} 
            ${data?.tcc_quote_b_b_self?.approved_user?.profile?.middle_name || ''} 
            ${data?.tcc_quote_b_b_self?.approved_user?.profile?.last_name || ''} 
            (ID: ${data?.tcc_quote_b_b_self?.approved_user?.id || '-'})` :
                '-'
        }
    ]

    useEffect(() => {
        if (data?.file_store && data?.file_store.length > 0) {
            setPaymentFilesState({ loading: true });
            fetchFiles(data?.file_store).then((files) => {
                const error = files?.findIndex(file => !file);
                if (error !== -1) {
                    notification.destroy();
                    notification.error({ placement: "top", message: "Error", description: "Retrieving one or more attachments failed." })
                }
                files = files?.filter(file => !!file);
                setPaymentFilesState({
                    fileStore: files,
                    loading: false
                });
            });
        }
        return () => {
            setPaymentFilesState({ fileStore: [] });
            setState({ note: undefined, ref: undefined });
        }
        // eslint-disable-next-line
    }, [data]);


    const repeatPayment = () => {
        setnewPaymentState({
            recipient: data?.tcc_beneficiary_full,
            resetRecipient: false,
            presetPayment: data,
            fundFromCollections: data?.fund_method_id === OPT_FUNDING_METHOD.VOLOPA_COLLECTION_ACCOUNT
        });
        navigate(URLS.NewPayment);
    }

    const cancelPayment = async () => {
        const quoteCancelRequestData = {};
        quoteCancelRequestData.shortReference = data?.trade_data?.short_reference;
        const quoteCancelResponse = await apiRequest("exchange/b2b/self/cancel/trade/quote", "POST", quoteCancelRequestData);
        if (quoteCancelResponse && quoteCancelResponse.data && quoteCancelResponse.data?.success) {
            if (parseFloat(quoteCancelResponse.data?.cancel?.GBP) >= parseFloat(process.env.REACT_APP_CANCEL_THRESHOLD_AMOUNT)) {
                const tradeCancelResponse = await apiRequest("exchange/b2b/self/cancel/trade", "POST", quoteCancelRequestData);
                if (tradeCancelResponse && tradeCancelResponse.data && tradeCancelResponse.data?.success) {
                    notification.success({ message: 'Cancelled', description: 'Payment has been successfully cancelled', placement: 'top' });
                    allowCancelTrade = false;
                }
            } else {
                notification.error({ message: 'Please Contact Support', description: 'In order to cancel this payment, you need to contact our support team via email (support@volopa.com) or phone (+44 333 400 1287)', placement: 'top' });
            }
        } else {
            notification.error({ message: 'Please Contact Support', description: 'In order to cancel this payment, you need to contact our support team via email (support@volopa.com) or phone (+44 333 400 1287)', placement: 'top' });
        }
    }
    //
    const handleFileStoreActions = () => {
        return {
            deleted: initData.fileStore.filter(item => fileStore.find(file => file.id === item.id) ? false : true),
            newFiles: fileStore.filter(file => !!file.originFileObj)
        }
    }

    //
    const handleSave = async () => {
        setState({ saveLoading: true })

        if (fileStoreChanged()) {
            const { deleted, newFiles } = handleFileStoreActions();

            if (deleted.length) {
                // @todo Missing del route
            }

            if (newFiles.length) {
                const formData = new FormData();

                newFiles.forEach((file, idx) => {
                    formData.append(`file[${idx}]`, file.originFileObj);
                });

                formData.append('payment_id', data?.id);

                expenseAttachmentMutation.mutateAsync(formData);
            }
        }

        let notesData = [];
        if (note || note === "") {
            notesData.push({
                type_id: 1,
                note: note
            });
        }
        if (ref || ref === "") {
            notesData.push({
                type_id: 2,
                note: ref
            });
        }

        const response = await apiRequest("usernotes", "POST", {
            referenced_id: data?.id,
            notes: notesData
        });

        if (response && response.data) {
            notification.success({ message: 'Changes saved successfully', placement: 'top' });
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'An Error Occured During Saving', placement: 'top' });
        }
        setState({ saveLoading: false });
        refetch();
    }

    //
    const fileStoreChanged = () => {
        return !isEqual(
            fileStore,
            initData.fileStore,
        )
    }

    //
    const updateNotes = (val) => {
        setState({ note: val.target.value });
    }
    const updateRef = (val) => {
        setState({ ref: val.target.value });
    }
    const getNoteValue = () => {
        if (data?.notes) {
            let noteData = data?.notes?.filter((data) => data?.type_id === 1);
            return (noteData && noteData[0] && noteData[0].note) ? noteData[0].note : '';
        }
        return '';
    }
    const getRefValue = () => {
        if (data?.notes) {
            let refData = data?.notes?.filter((data) => data?.type_id === 2);
            return (refData && refData[0] && refData[0].note) ? refData[0].note : '';
        }
        return '';
    }

    //
    const downLoadPDF = async () => {
        const resp = await extendedApiRequest({
            baseUrl: process.env.REACT_APP_API_BASE_DOWNLOAD_URL,
            path: 'paymentReport?map_id=' + data?.id,
            method: 'GET',
            config: {
                responseType: "blob",
            },

        });
        const acceptedFileTypes = {
            'application/pdf': '.pdf',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
            'text/csv': '.csv',
        };

        if (!resp.size || !resp.type || !Object.keys(acceptedFileTypes).includes(resp.type)) {
            notification.error({ message: 'Something went wrong!', placement: 'top' });
            return;
        }
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(resp);
        link.download = `${data?.id}.pdf`;
        link.click();
    }

    //
    const downloadMt103Pdf = async () => {
        try {
            const resp = await extendedApiRequest({
                baseUrl: process.env.REACT_APP_AUX_API_BASE_URL,
                path: 'tcc/b2b/generate/payment/submission/' + data?.payment?.tcc_payment_uid + '/pdf',
                method: 'GET',
                config: {
                    responseType: "blob",
                },
                onDownloadProgress: () => { },
            });
            const acceptedFileTypes = {
                'application/pdf': '.pdf',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
                'text/csv': '.csv',
            };

            if (!resp.size || !resp.type || !Object.keys(acceptedFileTypes).includes(resp.type)) {
                notification.error({ message: 'Something went wrong!', placement: 'top' });
                return;
            }

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(resp);
            link.download = `mt103-${data?.id}.pdf`;
            link.click();
        } catch {
            notification.error({ message: 'Something went wrong!', placement: 'top' });
        }
    }

    const handleDeleteDraft = async () => {
        setState({ cancelLoading: true });
        const body = { uuid: data?.tcc_quote_b_b_self?.uuid }
        const res = await apiRequest(APIROUTES.post.cancel_draft, 'POST', body);
        setState({ cancelLoading: false });
        notification.destroy();
        if (res?.data?.success) {
            notification.success({
                placement: 'top',
                message: 'Scheduled payment cancelled'
            })
            navigate(URLS.DraftPayments);
        } else {
            notification.error({
                placement: 'top',
                message: 'Error',
                description: 'Scheduled payment not cancelled'
            })
        }
    }

    return (
        <>
            <Spin size="large" spinning={activateLoading || accountStatusFetching}>
                <MT103Modal modalOpen={openMt103Modal} downloadMt103Pdf={downloadMt103Pdf} onCancel={() => setState({ openMt103Modal: false })} description={mt103details} hideMt103Button={hideMt103Button} />
                <Row>
                    <Col span={24}>
                        <PageDescription title='Specific Payment History' text='- Details of International Payment' />
                    </Col>
                </Row>
                {(isFetching) ?
                    <Row justify="center" className="m-t-20">
                        <Col>
                            <Spin size="large" spinning />
                        </Col>
                    </Row> :
                    <>
                        <Row className="m-t-20" gutter={[48, 12]} justify='center'>
                            <Col xxl={12} xl={12} lg={14} md={16} sm={20} xs={24}>
                                {historyData && historyData.length > 0 && historyData.map((val, key) => (
                                    <Card
                                        className={(key % 2 === 0 ? 'b-g' : 'b-none') + ' bg-none small-rounded'}
                                        bodyStyle={{ padding: '10px 20px' }}
                                        key={key}
                                    >
                                        <Row gutter={12}>
                                            <Col span={12}>
                                                <Typography.Text className="fs-18px medium">{val.title}</Typography.Text>
                                            </Col>
                                            <Col span={12}>
                                                <Typography.Text className="fs-18px medium" type={val.type}>{val.data}</Typography.Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                ))}
                            </Col>
                            <Col xxl={12} xl={12} lg={14} md={16} sm={20} xs={24} >
                                <Row justify="end">
                                    <Button type="primary" onClick={() => downLoadPDF()}>
                                        <Space>
                                            <FaFileDownload size={18} style={{ marginBottom: "-2px" }} /> Download PDF Statement
                                        </Space>
                                    </Button>
                                </Row>
                                <Row>
                                    <Col>
                                        <Typography.Text className="fs-20px dark-green medium">User Submission & Approval Details</Typography.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        {submissionData?.map((val, key) => (
                                            <Card
                                                className={(key % 2 === 0 ? 'b-g' : 'b-none') + ' bg-none small-rounded'}
                                                bodyStyle={{ padding: '10px 20px' }}
                                                key={key}
                                            >
                                                <Row gutter={12}>
                                                    <Col span={12}>
                                                        <Typography.Text className="fs-18px medium">{val.title}</Typography.Text>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Typography.Text className="fs-18px medium" type={val.type}>{val.data}</Typography.Text>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        ))}
                                    </Col>
                                </Row>
                                <Row className="m-t-40">
                                    <Col span={24}>
                                        <Typography.Text className="fs-20px dark-green medium">Funding Bank Account</Typography.Text>
                                        <Card className="bg-light-grey" bodyStyle={{ padding: '10px' }} loading={loading}>
                                            <Row className='m-t-10' gutter={[24, 12]} justify='space-between'>
                                                {showPaymentsUnder && <Col flex='auto'>
                                                    <Row justify='center'>
                                                        <Col>
                                                            <Button
                                                                size='small'
                                                                type='primary'
                                                                className={!fundingLimitReached ? 'bg-light-green b-g medium' : ''}
                                                                ghost={fundingLimitReached}
                                                                onClick={(e) => updateFundingDetails(e, false)}
                                                            >
                                                                {config.is_eea === 1 ? "Regular/SEPA Payment Up To A Value Of EUR 250,000" : "Regular / Faster Payment Up To A Value Of £250,000.00"}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>}
                                                {showPaymentsOver && <Col flex='auto'>
                                                    <Row justify='center'>
                                                        <Col>
                                                            <Button
                                                                size='small'
                                                                type='primary'
                                                                ghost={!fundingLimitReached}
                                                                className={fundingLimitReached ? 'bg-light-green b-g medium' : ''}
                                                                onClick={(e) => updateFundingDetails(e, true)}
                                                            >
                                                                {config.is_eea === 1 ? "Payments Over EUR 250,00 Or SWIFT/TARGET2 Payments" : "Payments Over £250,000.00 Or BACS/CHAPS/SWIFT Payments"}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>}
                                            </Row>
                                            <Row gutter={[12, 12]} justify="space-between">
                                                <Col span={12}>
                                                    <Space size={0} direction='vertical'>
                                                        <Typography.Text className="dark-green fs-18px muli semi-bold">Account Holder Name</Typography.Text>
                                                        <Typography.Text className="dark-green fs-18px muli light">{fundingDetails?.volopaAccountHolderName}</Typography.Text>
                                                    </Space>
                                                </Col>
                                                <Col span={12}>
                                                    <Row justify="space-between" gutter={[12, 12]}>
                                                        <Col>
                                                            {fundingLimitReached ?
                                                                <Space direction="vertical" size={0}>
                                                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Account Number</Typography.Text>
                                                                    <Typography.Text className="muli light fs-18px">{fundingDetails?.volopaAccountNumber}</Typography.Text>
                                                                </Space> :
                                                                <Space direction="vertical" size={0}>
                                                                    <Typography.Text className="muli semi-bold fs-18px dark-green">{fundingDetails?.volopaAccountNumberType === "iban" ? "IBAN" : "Account Number"}</Typography.Text>
                                                                    <Typography.Text className="muli light fs-18px">{fundingDetails?.volopaAccountNumber}</Typography.Text>
                                                                </Space>
                                                            }
                                                        </Col>
                                                        <Col>
                                                            <Space size={0} direction='vertical'>
                                                                <Typography.Text className='muli semi-bold dark-green fs-18px'>{fundingDetails?.volopaAccountSortCodeLabel}</Typography.Text>
                                                                <Typography.Text className="dark-green fs-18px muli light">{fundingDetails?.volopaAccountSortCode}</Typography.Text>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            {fundingLimitReached &&
                                                <Row className='m-t-10' gutter={[12, 12]}>
                                                    <Col span={12}>
                                                        <Space direction='vertical' size={0}>
                                                            <Typography.Text className='muli semi-bold dark-green fs-18px'>IBAN</Typography.Text>
                                                            <Typography.Text className='muli fs-18px light dark-green'>{fundingDetails?.volopaAccountIban}</Typography.Text>
                                                        </Space>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Space direction='vertical' size={0}>
                                                            <Typography.Text className='muli semi-bold dark-green fs-18px'>SWIFT/BIC</Typography.Text>
                                                            <Typography.Text className='muli fs-18px light dark-green'>{fundingDetails?.volopaAccountSwift}</Typography.Text>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            }
                                            <Row gutter={12} className="m-t-10">
                                                <Col span={12}>
                                                    <Space size={0} direction='vertical'>
                                                        <Typography.Text className="dark-green fs-18px muli semi-bold">Bank Name</Typography.Text>
                                                        <Typography.Text className="dark-green fs-18px muli light">{fundingDetails?.volopaAccountName}</Typography.Text>
                                                    </Space>
                                                </Col>
                                                <Col span={12}>
                                                    <Row justify="space-between" gutter={[12, 12]}>
                                                        <Col>
                                                            <Space size={0} direction='vertical'>
                                                                <Typography.Text className="dark-green fs-18px muli semi-bold">Bank Address</Typography.Text>
                                                                <Typography.Text className="dark-green fs-18px muli light">{fundingDetails?.volopaAccountAddress}</Typography.Text>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row gutter={12} className="m-t-10">
                                                <Col span={24}>
                                                    {!fundingLimitReached ?
                                                        <Space direction='vertical' size={0}>
                                                            {fundingDetails?.volopaAccountCountry !== "" &&
                                                                <Typography.Text className='muli semi-bold dark-green fs-18px'>Bank Country</Typography.Text>
                                                            }
                                                            <Typography.Text className='muli fs-18px light dark-green'>{fundingDetails?.volopaAccountCountry}</Typography.Text>
                                                        </Space> :
                                                        <Space size={0} direction='vertical'>
                                                            <Typography.Text className="dark-green fs-18px muli semi-bold">Mandatory Funding Reference</Typography.Text>
                                                            <Typography.Text className="dark-green fs-18px muli light">{fundingDetails?.short_reference}</Typography.Text>
                                                        </Space>

                                                    }
                                                </Col>
                                            </Row>
                                            {fundingLimitReached && <Typography.Text className='muli semi-bold dark-green fs-12px'>*Please note that the Payment Reference is required to ensure we identify your funds efficiently</Typography.Text>}
                                            {config.is_eea === 1 && <Row className='m-t-10 m-b-10'><Typography.Text className='muli semi-bold dark-green fs-12px'>Please ensure that you transfer the funds from the bank account which you have verified. Please note that sending the funds from a different bank account may result in the payment being declined.</Typography.Text></Row>}
                                        </Card>
                                    </Col>
                                </Row>

                                <Row className="m-t-20">
                                    <Col>
                                        <Typography.Text className='fs-18px medium'>
                                            Notes
                                        </Typography.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Input.TextArea rows={2} onChange={(val) => { updateNotes(val) }} defaultValue={getNoteValue()} />
                                    </Col>
                                </Row>
                                <Row className="m-t-20">
                                    <Col>
                                        <Typography.Text className='fs-18px medium'>
                                            Invoice Reference Number
                                        </Typography.Text>
                                    </Col>
                                </Row>
                                <Row className="m-b-10">
                                    <Col span={24}>
                                        <Input onChange={(val) => { updateRef(val) }} defaultValue={getRefValue()} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="m-t-20" gutter={[48, 12]} justify='center' align='bottom'>
                            <Col xxl={12} xl={12} lg={14} md={16} sm={20} xs={24}>
                                <Row justify="space-between" className="m-t-20" align='bottom'>
                                    <Col>
                                        <Space direction="vertical" size={0}>
                                            <Typography.Text className="muli semi-bold fs-18px">
                                                Attachments
                                            </Typography.Text>
                                            <Space size={24}>
                                                <PaymentAttachmentUpload />
                                            </Space>
                                        </Space>
                                    </Col>
                                    <Col>
                                        <Row gutter={12} justify="right">
                                            {allowCancelTrade &&
                                                <Col>
                                                    <Popconfirm title={`Are you sure you want to cancel this ${batchId !== '-' ? `batch payment?` : `payment?`}`} placement="topRight" onConfirm={() => cancelPayment()} okText="Yes" cancelText="No">
                                                        <Button type="danger">Cancel Payment</Button>
                                                    </Popconfirm>
                                                </Col>
                                            }
                                            {!!data?.tcc_quote_b_b_self?.scheduled && !data?.tcc_quote_b_b_self?.accepted &&
                                                <Col>
                                                    <Popconfirm
                                                        title={`Are you sure you want to cancel this ${batchId !== '-' ? `batch payment?` : `payment?`}`}
                                                        placement="top"
                                                        onConfirm={handleDeleteDraft}
                                                        okText="Yes"
                                                        cancelText="No"
                                                        loading={cancelLoading}
                                                    >
                                                        <Button type="danger">Cancel Scheduled Payment</Button>
                                                    </Popconfirm>
                                                </Col>
                                            }
                                            <Col>
                                                <Tooltip
                                                    title="This account is currently frozen for administrative reasons. Our team will get in touch with you soon, if needed."
                                                    {...(!accountStatus?.suspended_accounts?.payments && { open: false })}
                                                >
                                                    <Button
                                                        type="primary"
                                                        onClick={() => repeatPayment()}
                                                        disabled={!!accountStatus?.suspended_accounts?.payments}
                                                    >
                                                        <Space>
                                                            <FaSyncAlt />
                                                            Repeat
                                                        </Space>
                                                    </Button>
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xxl={12} xl={12} lg={14} md={16} sm={20} xs={24} >
                                <Row justify="end">
                                    <Col >
                                        <Button type="primary" loading={saveLoading} onClick={() => handleSave()}>
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                }
            </Spin>
        </>
    )
}

export default SpecificPaymentHistory;
