/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, notification, Row, Space, Spin } from "antd";
import { ErrorView } from "App/Components/ErrorView";
import { useLoginStore } from "App/Pages/Login/store";
import { APIROUTES } from "Constants/ApiRoutes";
import { IMAGES } from "Constants/Images";
import { OPT_FEATURES } from "Constants/Opts";
import { useApiRequest } from "Hooks/API";
import { usePilotFeaturesQuery } from "Hooks/Queries";
import { useState } from "react";
import { Outlet } from "react-router-dom";

export default function PilotGuard({ featureId }) {
  const { data: pilotFeatures, isLoading } = usePilotFeaturesQuery();
  const apiRequest = useApiRequest();
  const config = useLoginStore(s => s.config);
  const pilotFeature = pilotFeatures?.find(feature => feature?.id === featureId)?.option;
  const enabled = pilotFeature === undefined || pilotFeature === config?.pilot?.[featureId];
  const [loading, setLoading] = useState(false);
  const setStore = useLoginStore(s => s.setStore);

  const handleRegisterInterest = async () => {
    setLoading(true);
    await apiRequest(APIROUTES.post.subscribe_news, "POST", { featureId: OPT_FEATURES.collections });
    notification.success({
      placement: "top",
      message: "Thanks for showing your interest.",
      description: `We will reach out to you once the new Collections service is launched.`
    })
    setLoading(false);
    setStore({ config: { ...config, registered_interest: true } });
  }

  return (
    isLoading ?
      <Row className="full-percent-height" align="middle" justify="center">
        <Col>
          <Spin spinning size="large" />
        </Col>
      </Row> :
      enabled ?
        <Outlet /> :
        <ErrorView
          title="International Collections coming soon."
          message={
            <Space direction="vertical">
              Stay tuned
              <Button
                type="primary"
                onClick={handleRegisterInterest}
                disabled={!!config?.registered_interest}
                loading={loading}
              >
                {!!config?.registered_interest ? "Registered" : "Register your Interest"}
              </Button>
            </Space>
          }
          icon={IMAGES.sandsOfTime}
        />
  )
}